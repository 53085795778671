import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { getOverviewData } from "../../../helpers/backend_helper";
import { useNavigate } from "react-router-dom";
import { Statistic } from "antd";
import { HomeOutlined, DollarOutlined } from "@ant-design/icons";
import CountUp from "react-countup";
import Summary from "../Summary/index";
import AntGauge from "../ChartTypes/AntDCharts/Gauge";
import StackedBarChart from "../ChartTypes/AntDCharts/StackedBarChart";
import Donut from "../ChartTypes/AntDCharts/Donut";
import Radial from "../ChartTypes/AntDCharts/Radial";
import { Alert } from "reactstrap";
import Spinner from "../../../components/Spinner/index";

const Main = ({ filterPeriod, filterRegion, filterYearFrom, filterYearTo , handleFilterOptions}) => {
  let navigate = useNavigate();

  //Average Days
  const [avgInvestigationDays, setAvgInvestigationDays] = useState();
  const [
    avgProsecutionDaysInSubordinateCourts,
    setAvgProsecutionDaysInSubordinateCourts,
  ] = useState();
  const [avgProsecutionDaysInHighCourt, setAvgProsecutionDaysInHighCourt] =
    useState();

  // Asset Data
  const [monetaryAssetsRecovered, setMonetaryAssetsRecovered] = useState();
  const [nonMonetaryAssetsRecovered, setNonMonetaryAssetsRecovered] =
    useState();

  //File Data
  const [casesFiledInCourt, setCasesFiledInCourt] = useState();
  const [casesEnded, setCasesEnded] = useState();

  //Case Status
  const [casesConvicted, setCasesConvicted] = useState();
  const [casesAcquitted, setCasesAcquitted] = useState();
  const [casesPending, setCasesPending] = useState();

  //Case Summary
  const [caseSummary, setCaseSummary] = useState();

  //Referrals By Type
  const [referralsByTypeData, setReferralsByTypeData] = useState([]);
  const [referralsByRegionData, setReferralsByRegionData] = useState([]);
  const [overviewDataLoaded, setOverviewDataLoaded] = useState(false);

  const [requestResponse, setRequestResponse] = useState([]);

  const formatter = (value) => <CountUp end={value} separator="," />;

  const initializeElements = () => {
    setAvgInvestigationDays("");
    setAvgProsecutionDaysInSubordinateCourts("");
    setAvgProsecutionDaysInHighCourt("");
    setCaseSummary("");
    setMonetaryAssetsRecovered("");
    setNonMonetaryAssetsRecovered("");
    setCasesFiledInCourt("");
    setCasesEnded("");
    setCasesConvicted("");
    setCasesAcquitted("");
    setCasesPending("");

    //Charts initialization
    setReferralsByRegionData("");
    setReferralsByTypeData("");
    setRequestResponse("")
  };

  const fetchOverviewData = () => {
    initializeElements();

    let selectedFilters = {
      filterPeriod: filterPeriod,
      filterRegion: filterRegion,
      filterYearFrom: filterYearFrom,
      filterYearTo: filterYearTo,
    };

    if (filterYearFrom && filterYearTo) {
      try {
        getOverviewData(selectedFilters).then((res) => {
          if (res === false) {
            navigate("/logout");
          } else {
            setAvgInvestigationDays(
              res.average_days.average_investigation_days
            );
            setAvgProsecutionDaysInSubordinateCourts(
              res.average_days.average_prosecution_days_in_subordinate_courts
            );
            setAvgProsecutionDaysInHighCourt(
              res.average_days.average_prosecution_days_in_high_court
            );

            setMonetaryAssetsRecovered(
              res.asset_data.total_monetary_assets_recovered
            );
            setNonMonetaryAssetsRecovered(
              res.asset_data.total_non_monetary_assets_recovered
            );

            setCasesFiledInCourt(res.case_data.total_gc_cases_filed_in_court);
            setCasesEnded(res.case_data.total_gc_cases_ended);

            setCasesConvicted(res.case_data.total_gc_cases_convicted);
            setCasesAcquitted(res.case_data.total_gc_cases_acquitted);
            setCasesPending(res.case_data.total_pending_cases_in_court);

            //Case Summary Array
            setCaseSummary(res.case_summary);

            setRequestResponse(res.requests_and_responses);

            //Referrals By Type
            setReferralsByTypeData(res.referral_disaggregation_by_type);
            setReferralsByRegionData(res.referral_disaggregation_by_region);

            // Show Alert if data is not fully submitted
            setOverviewDataLoaded(res.verify_submission);

            //Filter Options
            handleFilterOptions(res.filter_options)
          }
        });
      } catch (error) {
        console.error("Error occured in ", error);
      }
    }
  };

  useEffect(() => {
    fetchOverviewData();
  }, [filterPeriod]);


  return (
    <>
      {!overviewDataLoaded ? (
        <Alert color="warning">
          <div>
            <h2>Current Quarter Data posting is still in progress.</h2>
          </div>
        </Alert>
      ) : (
        <div>
          <Row>
            <Col xl={4} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts" dir="ltr">
                    <h2>Average Days Taken by PCCB to investigate GC Cases</h2>
                    {avgInvestigationDays !== "" ? (
                      <AntGauge
                        value={
                          avgInvestigationDays > 0
                            ? avgInvestigationDays / 365
                            : 0
                        }
                        numberOfDays={avgInvestigationDays}
                        ticks={[0, 30 / 73, 36 / 73, 1]}
                        max={365}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts" dir="ltr">
                    <h2>
                      {" "}
                      Average Days Taken by NPS to Prosecute GC Cases in
                      Subordinate Courts
                    </h2>
                    {avgProsecutionDaysInSubordinateCourts !== "" ? (
                      <AntGauge
                        value={
                          avgProsecutionDaysInSubordinateCourts > 0
                            ? avgProsecutionDaysInSubordinateCourts / 730
                            : 0
                        }
                        numberOfDays={avgProsecutionDaysInSubordinateCourts}
                        ticks={[0, 22 / 73, 27 / 73, 1]}
                        max={730}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts" dir="ltr">
                    <h2>
                      Average Days Taken by NPS to Prosecute GC Cases in High
                      Court
                    </h2>
                    {avgProsecutionDaysInHighCourt !== "" ? (
                      <AntGauge
                        value={
                          avgProsecutionDaysInHighCourt > 0
                            ? avgProsecutionDaysInHighCourt / 730
                            : 0
                        }
                        numberOfDays={avgProsecutionDaysInHighCourt}
                        ticks={[0, 22 / 73, 27 / 73, 1]}
                        max={730}
                      />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12} sm={12}>
              {caseSummary ? (
                <Summary caseSummaryData={caseSummary} />
              ) : (
                <Spinner />
              )}
            </Col>
          </Row>

          <Row>
            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  {monetaryAssetsRecovered !== "" ? (
                    <Statistic
                      title="Monetary Assets Recovered (in TZS)"
                      value={monetaryAssetsRecovered}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={
                        <DollarOutlined
                          style={{ fontSize: "175%", marginRight: "10px" }}
                        />
                      }
                      suffix="TZS"
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  {nonMonetaryAssetsRecovered !== "" ? (
                    <Statistic
                      title="Non-Monetary Assets Recovered (in TZS)"
                      value={nonMonetaryAssetsRecovered}
                      valueStyle={{ color: "#512da8" }}
                      prefix={
                        <HomeOutlined
                          style={{ fontSize: "175%", marginRight: "10px" }}
                        />
                      }
                      suffix="TZS"
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={3} sm={12}>
              <Card>
                <CardBody>
                  {casesEnded !== "" ? (
                    <Statistic
                      title="Total Aggregate number of GC cases ended in Court"
                      value={casesEnded}
                      formatter={formatter}
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xl={3} sm={12}>
              <Card>
                <CardBody>
                  {casesConvicted !== "" ? (
                    <Statistic
                      title="Aggregate number of Grand Corruption cases convicted"
                      value={casesConvicted}
                      formatter={formatter}
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xl={3} sm={12}>
              <Card>
                <CardBody>
                  {casesAcquitted !== "" ? (
                    <Statistic
                      title="Aggregate number of  Grand Corruption cases acquitted"
                      value={casesAcquitted}
                      formatter={formatter}
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col xl={3} sm={12}>
              <Card>
                <CardBody>
                  {casesPending !== "" ? (
                    <Statistic
                      title="Aggregate number of Grand Corruption cases pending in Court"
                      value={casesPending}
                      formatter={formatter}
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts" dir="ltr">
                    <h2>Referrals sent to PCCB Disaggregated by Regions</h2>
                    {referralsByRegionData !== "" ? (
                      <Radial loadedData={referralsByRegionData} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts" dir="ltr">
                    <h2>
                      Referrals sent to PCCB Disaggregated by Referral Type
                    </h2>
                    {referralsByTypeData !== "" ? (
                      <Donut loadedData={referralsByTypeData} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts" dir="ltr">
                    <h2>Requests and Responses between PCCB and BIs</h2>
                    {requestResponse ? (
                      <StackedBarChart loadedData={requestResponse} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Main;
