import React, { useEffect, useState } from "react";
import { CardTitle, Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getFileDetails,
  getInstutions,
  createFileTracker,
} from "../../helpers/backend_helper";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import {
  Col,
  Row,
  CardBody,
  Label,
  Modal,
  Form,
  Card,
  Input,
} from "reactstrap";
import NewFile from "./FileMovement/new-file";
import UpdateFile from "./FileMovement/update-file";
import TrackFile from "./RegistryFiles/track-file";
import FileTabs from "./FileMovement/file-tabs";
import SearchBar from "./search-bar";
import Select from "react-select";
import Spinner from "../../components/Spinner/index";
import { Tooltip } from "antd";
import { cyan } from "@mui/material/colors";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  postFileDetails,
  getCoordinators,
  getRegions,
  getFileTracker,
  getInvestigationOfficers,
} from "../../helpers/backend_helper";
import { Alert } from "@mui/material";
import TextArea from "antd/es/input/TextArea";

const Main = () => {
  const navigate = useNavigate();
  let role = localStorage.getItem("role");

  const [files, setFiles] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [newFile, setNewFile] = useState(false);
  const [updateFile, setUpdateFile] = useState(false);
  const [trackFile, setTrackFile] = useState(false);
  const [loadedFiles, setLoadedFiles] = useState(0);

  const [fileId, setFileId] = useState();
  const [referenceNo, setReferenceNo] = useState();
  const [irNo, setIrNo] = useState();
  const [dateSent, setDateSent] = useState();
  const [dateReceived, setDateReceived] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [fileOriginId, setFileOriginId] = useState();

  const [coordinators, setCoordinators] = useState([]);
  const [regions, setRegions] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [startFlowModalBackdrop, setStartFlowModalBackdrop] = useState(false);
  const [sendModalBackdrop, setSendModalBackdrop] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState("");
  const [selectedInstitutionUrl, setSelectedInstitutionUrl] = useState("");
  const [selectedInstitutionUsername, setSelectedInstitutionUsername] =
    useState("");
  const [selectedInstitutionPassword, setSelectedInstitutionPassword] =
    useState("");

  //Error messages
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showConflictError, setShowConflictError] = useState(false);

  //Flow Management
  const [showFileTracker, setShowFileTracker] = useState(false);
  const [showFileRegistry, setShowFileRegistry] = useState(true);

  const [selectedFileStatus, setSelectedFileStatus] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCoordinator, setSelectedCoordinator] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [hasBranches, setHasBranches] = useState(false);
  const [chargedInCourt, setChargedInCourt] = useState(false);
  const [selectedOffence, setSelectedOffence] = useState("");
  const [moreOffenceDetails, setMoreOffenceDetails] = useState("");

  const [errorMessage, setErrorMessage] = useState("");


  const [investigationOfficers, setInvestigationOfficers] = useState([]);

  
  const handleSearchOnChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleFileStatusChange = (event) => {
    setSelectedFileStatus(event.value);
  };

  const reloadFiles = () => {
    setLoadedFiles(loadedFiles + 1);
    fetchFiles();
  };


  //Seeder Data
  const offences = [
    {
      value: "money_laundering",
      label: "Money Laundering",
    },
    {
      value: "terrorist_financing",
      label: "Terrorist Financing",
    },
    {
      value: "proliferation_of_weapons_of_mass_destruction",
      label: "Proliferation of Weapons of Mass Destruction",
    },
  ];

  //Datatable initialization
  const columns = [
    {
      name: "#",
      width: "3rem",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: <span className="font-weight-bold fs-13">File Reference No</span>,
      selector: (row) => row.reference_no,
      width: "15rem",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">File IR No</span>,
      selector: (row) => row.ir_no,
      width: "15rem",
      sortable: true,
    },
    {
      id: "dateSent",
      name: <span className="font-weight-bold fs-13">File Sent On</span>,
      width: "10rem",
      sortable: true,
      cell: (row) => {
        if (row.date_sent) {
          return moment(row.date_sent).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">File Received On</span>,
      width: "10rem",
      sortable: true,
      cell: (row) => {
        if (row.date_sent) {
          return moment(row.date_received).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">File Status</span>,
      selector: (row) => row.file_status,
      width: "15rem",
      sortable: false,
      cell: (row) => {
        switch (row.is_internal_file) {
          case true:
            return (
              <span className="badge badge-soft-info">
                {" "}
                Registered as internal file
              </span>
            );
          case false:
            return (
              <span className="badge badge-soft-success">
                Received from partner institution
              </span>
            );
          default:
            return <Label>None</Label>;
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">File origin</span>,
      selector: (row) => (row.origin !== null ? row.origin.acronym : ""),
      width: "5rem",
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: false,
      width: "30rem",
      cell: (row) => {
        return (
          <>
            <div className="d-flex flex-wrap gap-2">
              <Tooltip title="Update File" color={cyan} key={cyan}>
                <button
                  className="btn-primary"
                  onClick={(e) =>
                    handleUpdateFile(
                      row.id,
                      row.reference_no,
                      row.ir_no,
                      row.date_sent,
                      row.date_received,
                      row.origin.id
                    )
                  }
                >
                  <i className="fas fa-edit" />
                </button>
              </Tooltip>

              <div className="d-flex flex-wrap gap-2">
                <Tooltip title="Start Moving File" color={cyan} key={cyan}>
                  <button
                    className="btn btn-success"
                    onClick={(e) =>
                      handleAssignFile(e, row.id, row.reference_no)
                    }
                  >
                    <i className="fas  fa-arrows-alt-h" />
                  </button>
                </Tooltip>
              </div>

              <div className="d-flex flex-wrap gap-2">
                <Tooltip title="Trace File" color={cyan} key={cyan}>
                  <button
                    className="btn btn-info"
                    onClick={(e) => {
                      handleTrackFile(
                        row.id,
                        row.reference_no,
                        row.date_sent,
                        row.date_received
                      );
                    }}
                  >
                    <i className="fas fa-search align-middle"> </i>
                  </button>
                </Tooltip>
              </div>

              <div className="d-flex flex-wrap gap-2">
                <Tooltip title="Send File" color={cyan} key={cyan}>
                  <button
                    className="btn btn-primary"
                    onClick={(e) => handleSendFile(e)}
                  >
                    <i className="fas fa-share"> </i>
                  </button>
                </Tooltip>
              </div>

              <Modal
                isOpen={sendModalBackdrop}
                toggle={() => {
                  setSendModalBackdrop(!sendModalBackdrop);
                }}
              >
                <div className="modal-header">
                  <h5 className="modal-title">
                    Send File to other institution
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Form
                    className="form-group"
                    onSubmit={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  >
                    <Row>
                      <h3>File Receiving Details</h3>
                    </Row>

                    <Col xs={12} style={{ marginTop: "20px" }}>
                      <label htmlFor="institutions" className="col-form-label">
                        File Destination
                      </label>
                      <Select
                        name="institutions"
                        placeholder="Select Institution"
                        options={institutions.filter(
                          (institution) => institution.url !== null
                        )}
                        onChange={handleInstitution}
                      />
                    </Col>

                    <Row style={{ marginTop: "20px" }}>
                      <h3 style={{ textEmphasis: true }}>
                        API URL : {selectedInstitutionUrl}
                      </h3>
                    </Row>
                  </Form>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-danger"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setSendModalBackdrop(!sendModalBackdrop);
                      setSelectedInstitutionUrl();
                    }}
                  >
                    Close
                  </button>
                  <button type="button" className="btn-success">
                    <i className="fas fa-paper-plane"></i> Send File
                  </button>
                </div>
              </Modal>

              <Modal
                isOpen={startFlowModalBackdrop}
                toggle={() => {
                  setStartFlowModalBackdrop(!startFlowModalBackdrop);
                }}
              >
                {showConflictError ? (
                  <Alert color="warning">{errorMessage}</Alert>
                ) : (
                  ""
                )}

                <div className="modal-header">
                  <h5 className="modal-title">Start Moving File</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      setStartFlowModalBackdrop(false);
                    }}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <Form
                    className="form-group"
                    onSubmit={(e) => {
                      e.preventDefault();
                      AssignFileValidation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mb-3">
                      <label htmlFor="referenceNo" className="col-form-label">
                        File Reference Number:
                      </label>
                      <Input
                        disabled
                        name="referenceNo"
                        className="form-control"
                        placeholder="Reference No"
                        type="text"
                        onChange={fileMovementValidation.handleChange}
                        onBlur={fileMovementValidation.handleBlur}
                        value={referenceNo}
                        invalid={
                          fileMovementValidation.touched.referenceNo &&
                          fileMovementValidation.errors.referenceNo
                            ? true
                            : false
                        }
                      />

                      <Col xs={12} style={{ marginTop: "20px" }}>
                        <label
                          htmlFor="investigation-officers"
                          className="col-form-label"
                        >
                          File Status
                        </label>
                        <Select
                          name="file-status"
                          placeholder="Select File Status"
                          options={registryFileStatus}
                          onChange={handleFileStatusChange}
                        />
                      </Col>

                      {selectedFileStatus ===
                      "sent_to_coordinator_by_registry" ? (
                        <Col xs={12} style={{ marginTop: "20px" }}>
                          <label
                            htmlFor="coordinator"
                            className="col-form-label"
                          >
                            Coordinator
                          </label>
                          <Select
                            name="coordinator"
                            placeholder="Select Coordinator"
                            options={coordinators}
                            onChange={handleCoordinator}
                          />
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedFileStatus === "sent_to_partner_institution" ? (
                        <>
                          <Col xs={12} style={{ marginTop: "20px" }}>
                            <label
                              htmlFor="institutions"
                              className="col-form-label"
                            >
                              File Destination
                            </label>
                            <Select
                              name="institutions"
                              placeholder="Select Institution"
                              options={institutions.filter((institution) => !institution.isHost)}
                              onChange={handleInstitution}
                            />
                          </Col>

                          {hasBranches ? (
                            <Col xs={12} style={{ marginTop: "20px" }}>
                              <label
                                htmlFor="regions"
                                className="col-form-label"
                              >
                                Regions
                              </label>
                              <Select
                                name="regions"
                                placeholder="Select Region"
                                options={regions}
                                onChange={handleRegion}
                              />
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col xs={12} style={{ marginTop: "20px" }}>
                            <label htmlFor="reasons" className="col-form-label">
                              Reason
                            </label>
                            <Select
                              name="reasons"
                              placeholder="Select Reason"
                              options={reasons.filter(
                                (item) => item.type === "registry"
                              )}
                              onChange={handleReasons}
                            />
                          </Col>

                          {chargedInCourt ? (
                            <>
                              <Col xs={12} style={{ marginTop: "20px" }}>
                                <label
                                  htmlFor="offences"
                                  className="col-form-label"
                                >
                                  Offence
                                </label>
                                <Select
                                  name="offences"
                                  placeholder="Select Offence"
                                  options={offences}
                                  onChange={handleOffences}
                                />
                              </Col>

                              <Col xs={12} style={{ marginTop: "20px" }}>
                                <label
                                  htmlFor="offences"
                                  className="col-form-label"
                                >
                                  Please enter more details about the charge
                                </label>
                                <TextArea onChange={handleMoreDetails} />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Form>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-danger"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setStartFlowModalBackdrop(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn-primary"
                    onClick={() =>
                      handleNewFileTracker(startFlowModalBackdrop)
                    }
                  >
                    Save
                  </button>
                </div>
              </Modal>
            </div>
          </>
        );
      },
    },
  ];

  // Handle events
  const handleNewFile = () => setNewFile(!newFile);

  const handleTrackFile = (fileId, referenceNo,irNo, dateSent, dateReceived) => {
    setTrackFile(!trackFile);
    setFileId(fileId);
    setReferenceNo(referenceNo);
    setIrNo(irNo)
    setDateSent(dateSent);
    setDateReceived(dateReceived);
  };

  const handleUpdateFile = (
    fileId,
    referenceNo,
    irNo,
    dateSent,
    dateReceived,
    institution
  ) => {
    setFileId(fileId);
    setReferenceNo(referenceNo);
    setIrNo(irNo)
    setDateSent(dateSent);
    setDateReceived(dateReceived);

    setUpdateFile(!updateFile);

    setFileOriginId(institution);
  };

  const handleShowFileTracker = () => {
    setShowFileRegistry(!showFileRegistry);
    setShowFileTracker(!showFileTracker);
  };

  const handleInstitution = (event, url) => {
    setSelectedInstitution(event.value);

    let institution = institutions.filter(
      (institution) => institution.value === event.value
    );

    setSelectedInstitutionUrl(institution[0].url);
    setSelectedInstitutionUsername(institution[0].username);
    setSelectedInstitutionPassword(institution[0].password);
  };

  const handleSendFile = (e, modal, fileTrackerId, fileId, referenceNo) => {
    e.preventDefault();

    setSendModalBackdrop(!sendModalBackdrop);
  };

  const handleAssignFile = (e, fileId, referenceNo) => {
    e.preventDefault();

    setFileId(fileId);
    setReferenceNo(referenceNo);

    setStartFlowModalBackdrop(!startFlowModalBackdrop);
  };

  const handleOffences = (event) => {
    setSelectedOffence(event.value);
  };

  const handleMoreDetails = (event) => {
    setMoreOffenceDetails(event.target.value);
  };

  const handleRegion = (event) => {
    setSelectedRegion(event.value);
  };

  const handleCoordinator = (event) => {
    setSelectedCoordinator(event.value);
  };

  const handleReasons = (event) => {
    setSelectedReason(event.value);

    if (event.value === "charged_in_court") {
      setChargedInCourt(true);
    } else {
      setChargedInCourt(false);
    }
  };


  // Submit File Tracker Updates
  const handleNewFileTracker = (modal) => {
    let obj = {
      fileId: fileId,
      fileStatus: selectedFileStatus,

      coordinatorId: selectedCoordinator,
      institutionId: selectedInstitution,

      selectedReason: selectedReason,
      selectedRegion: selectedRegion,

      offence: selectedOffence,
      moreDetails: moreOffenceDetails,
    };

    createFileTracker(obj)
      .then((res) => {
        if (res.status === 200) {
          setShowAlert(true);
          setShowConfirmMsg(false);
          // handleReloadFiles();

          // disable other modal forms from other tabs
          setStartFlowModalBackdrop(false);

          // disable previous selected options from other modal forms in registry, coodinator, investigation and partner institutions
          setSelectedCoordinator("");
          setSelectedInstitution("");

          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
      })
      .catch((err) => {
        setErrorMessage(
          "Request Failed. File has already been assigned to a staff member for execution."
        );
        setShowConflictError(true);
        setTimeout(() => {
          setShowConflictError(false);
        }, 2000);
      });
  };

  //API Calls
  const fetchFiles = () => {
    setFiles([]);

    try {
      getFileDetails(null).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setFiles(res);
        }

        setIsLoaded(true);
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  const fetchInstitutions = () => {
    setInstitutions([]);
    try {
      getInstutions(null).then((res) => {
        if (res.status === false) {
          navigate("/logout");
        } else {
          res.map((res) => {
            setInstitutions((data) => [
              ...data,
              {
                value: res.id,
                label: `${res.description}`,
                url: res.file_sharing_url,
                username: res.destination_username,
                password: res.destination_password,
                isHost: res.is_host
              },
            ]);
          });
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  const fetchRegions = async () => {
    setRegions([]);

    try {
      getRegions(null).then((res) => {
        if (res.status === false) {
          navigate("/logout");
        } else {
          res.map((data) => {
            const value = data.id;
            const label = data.description;

            let dataObject = { value: value, label: label };

            setRegions((current) => [...current, dataObject]);
          });
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };


  const fetchInvestigationOfficers = () => {
    setInvestigationOfficers([]);
    try {
      getInvestigationOfficers(null).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          res.map((res) => {
            setInvestigationOfficers((data) => [
              ...data,
              {
                value: res.id,
                label: `${res.user.first_name} ${res.user.last_name}`,
              },
            ]);
          });
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  const fetchCoordinators = () => {
    setCoordinators([]);
    try {
      getCoordinators(null).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          res.map((res) => {
            setCoordinators((data) => [
              ...data,
              {
                value: res.id,
                label: `${res.user.first_name} ${res.user.last_name}`,
              },
            ]);
          });
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  // Start Flow logic

  //Reasons
  const reasons = [
    {
      value: "preliminary_investigation",
      label: "Preliminary Investigation",
      type: "coordinator",
    },
    {
      value: "further_investigation",
      label: "Further Investigation",
      type: "coordinator",
    },
    {
      value: "legal_opinion",
      label: "Legal Opinion",
      type: "coordinator",
    },
    {
      value: "disciplinary_action",
      label: "Disciplinary Action",
      type: "registry",
    },
    {
      value: "charged_in_court",
      label: "Charged In Court",
      type: "registry",
    },
    {
      value: "further_clarification",
      label: "Further Clarification",
      type: "registry",
    },
    {
      value: "closed_no_further_action",
      label: "Closed - No Further Action",
      type: "registry",
    },
    {
      value: "closed_no_offence_detected",
      label: "Closed - No Offence Detected",
      type: "registry",
    },
    {
      value: "closed_civil",
      label: "Closed - Civil",
      type: "registry",
    },
  ];

  const registryFileStatus = [
    {
      value: "sent_to_coordinator_by_registry",
      label: "File moved to Coordinator for review",
    },
    {
      value: "reopened_file_sent_to_coordinator_by_registry",
      label: "File re-opened and moved to Coordinator for review",
    },
    {
      value: "sent_to_partner_institution",
      label: "File dispatched to Partner Institution",
    },
    {
      value: "end_file_movement",
      label: "End File Movement",
    },
  ];

  // New file
  const fileMovementValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      referenceNo: "",
      dateSent: "",
      dateReceived: "",
    },
    validationSchema: Yup.object({
      referenceNo: Yup.string().required("Please enter reference no"),
      dateSent: Yup.string().required("Please enter date time sent"),
      dateReceived: Yup.string().required("Please enter date time received"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      values.institutionId = selectedInstitution;

      postFileDetails(values).then((res) => {
        if (res.status === 200) {
          setShowAlert(true);
          setShowConfirmMsg(false);

          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        } else {
          setShowError(true);

          setTimeout(() => {
            setShowError(false);
          }, 2000);
        }
      });

      setSubmitting(false);
      resetForm();

      //   handleReloadFiles();
    },
  });

  // Modal form
  const AssignFileValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      referenceNo: { referenceNo },
    },
    validationSchema: Yup.object({
      referenceNo: Yup.string().required("Please enter reference no"),
    }),
    onSubmit: (values) => {
      postFileDetails(values).then((res) => {
        if (res.status === 200) {
          setShowAlert(true);
          setShowConfirmMsg(false);

          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        } else {
          setShowError(true);

          setTimeout(() => {
            setShowError(false);
          }, 2000);
        }
      });
    },
  });

  // Effects
  useEffect(() => {
    fetchFiles();
  }, [loadedFiles]);

  useEffect(() => {
    fetchInstitutions();
  }, []);

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    fetchCoordinators();
  }, []);


  useEffect(() => {
    fetchInvestigationOfficers();
  }, []);

  useEffect(() => {
    if (role === "investigation_officer") {
      setShowFileRegistry(false);
      setShowFileTracker(true);
    }
  }, []);

  
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={localStorage.getItem("project")}
            breadcrumbItem="File Movement"
          />
        </Container>

        {newFile ? (
          <NewFile
            handleReturn={handleNewFile}
            handleReloadFiles={reloadFiles}
          />
        ) : updateFile ? (
          <UpdateFile
            handleReturn={handleUpdateFile}
            handleReloadFiles={reloadFiles}
            fileId={fileId}
            referenceNo={referenceNo}
            irNo={irNo}
            dateSent={dateSent}
            dateReceived={dateReceived}
            institutions={institutions}
            fileOriginId={fileOriginId}
          />
        ) : trackFile ? (
          <TrackFile
            handleReturn={handleTrackFile}
            fileId={fileId}
            referenceNo={referenceNo}
            dateSent={dateSent}
            dateReceived={dateReceived}
          />
        ) : showFileRegistry ? (
          <Card>
            <CardBody>
              {" "}
              <CardTitle>Files in the Registry</CardTitle>
              <Col>
                <CardBody>
                  <div className="d-flex flex-wrap gap-2">
                    <button className="btn btn-success" onClick={handleNewFile}>
                      <i className="mdi mdi-file"></i> Create new file
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleShowFileTracker}
                    >
                      <i className="fas fa-arrows-alt-h"></i> File Movement
                    </button>
                  </div>
                </CardBody>
              </Col>
              <Col>
                <CardBody>
                  <SearchBar handleSearchOnChange={handleSearchOnChange} />

                  <Col>
                    {isLoaded ? (
                      <DataTable
                        columns={columns}
                        data={files
                          .filter(
                            (item) =>
                              item.reference_no
                                .toLowerCase()
                                .includes(searchText.toLowerCase()) ||
                              item.origin.description
                                .toLowerCase()
                                .includes(searchText.toLowerCase()) ||
                              item.origin.acronym
                                .toLowerCase()
                                .includes(searchText.toLowerCase())
                          ).sort((a, b) =>
                          a.reference_no < b.reference_no ? -1 : 1)}
                        defaultSortFieldId="dateSent"
                        noDataComponent="No available data"
                        pagination
                      />
                    ) : (
                      <Spinner />
                    )}
                  </Col>
                </CardBody>
              </Col>
            </CardBody>
          </Card>
        ) : showFileTracker ? (
          <Card>
            <FileTabs
              role={role}
              reasons={reasons}
              offences={offences}
              registryFileStatus={registryFileStatus}
              handleNewFile={handleNewFile}
              handleUpdateFile={handleUpdateFile}
              handleReloadFiles={reloadFiles}
              handleShowFileTracker={handleShowFileTracker}
              coordinators={coordinators}
              institutions={institutions}
              regions={regions}
              investigationOfficers={investigationOfficers}
            />
          </Card>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Main;
