// Import Images
import img2 from "../../assets/images/users/avatar-2.jpg";
import img3 from "../../assets/images/users/avatar-3.jpg";
import img6 from "../../assets/images/users/avatar-6.jpg";
import img4 from "../../assets/images/users/avatar-4.jpg";


// Overview
const OverViewData = [
    {
        id: 1,
        title: "Disciplinary Action",
        color: "success"
    },
    {
        id: 3,
        title: "Charged in Court",
        color: "info"
    },
    {
        id: 2,
        title: "Closed",
        color: "danger"
    },
    {
        id: 2,
        title: "Further Clarification",
        color: "warning"
    },
  
  
];

 
// Notifications

export { OverViewData}