import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Label,
  Button,
  FormFeedback,
  Form,
  Col,
  CardTitle,
  CardBody,
  Alert,
} from "reactstrap";
import InputMask from "react-input-mask";
import { Card, CardContent } from "@mui/material";
import { useFormik } from "formik";
import { updateFileDetails } from "../../../helpers/backend_helper";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";

const Main = ({
  handleReturn,
  handleReloadFiles,
  fileId,
  referenceNo,
  dateSent,
  dateReceived,
  institutions,
  fileOriginId,
  irNo,
}) => {
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadedFileTracker, setLoadedFileTracker] = useState(0);
  const [selectedInstitution, setSelectedInstitution] = useState("");
  const [isInternalFile, setIsInternalFile] = useState(false);

  // Submission confirmation
  const handleConfirm = () => {
    if (!showConfirmMsg) {
      setShowConfirmMsg(true);
    }
  };

  const handleCancelConfirm = () => {
    setShowConfirmMsg(false);
  };

  const handleFileOrigin = (event) => {
    setIsInternalFile(!isInternalFile);
  };

  // New file
  const updateFileValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      fileId: fileId,
      referenceNo: referenceNo,
      irNo: irNo,
      dateSent: dateSent,
      dateReceived: dateReceived,
    },
    validationSchema: Yup.object({
      referenceNo: Yup.string().required("Please enter reference no"),
      irNo: Yup.string().required("Please enter IR no"),
      dateSent: Yup.string().required("Please enter date time sent"),
      dateReceived: Yup.string().required("Please enter date time received"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (selectedInstitution === "" || selectedInstitution === undefined) {
        values.originId = fileOriginId;
      } else {
        values.originId = selectedInstitution;
      }
      values.isInternalFile = isInternalFile;

      updateFileDetails(values).then((res) => {
        if (res.status === 200) {
          setShowAlert(true);
          setShowConfirmMsg(false);

          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        } else {
          setShowError(true);

          setTimeout(() => {
            setShowError(false);
          }, 2000);
        }
      });

      setSubmitting(false);
      // setLoadedFileTracker(loadedFileTracker + 1);

      resetForm();

      handleReloadFiles();
    },
  });

  const handleInstitution = (event) => {
    setSelectedInstitution(event.value);
  };

  return (
    <Card>
      <CardTitle style={{ margin: "20px" }}>Update File</CardTitle>
      {showAlert ? (
        <Alert color="success">
          <div>File successfully updated.</div>
        </Alert>
      ) : null}
      {showError ? (
        <Alert color="danger">
          <div>File creation failed.</div>
        </Alert>
      ) : null}
      <CardBody>
        <div style={{ margin: "20px" }}>
          <Link className="btn btn-primary" onClick={handleReturn}>
            <i className="fas fa-reply me-1 align-middle"> </i> Go back
          </Link>
        </div>
      </CardBody>
      <CardBody>
        <Form
          className="form-group"
          onSubmit={(e) => {
            e.preventDefault();
            updateFileValidation.handleSubmit();
            return false;
          }}
        >
          <div className="form-group">
            <Row className="m-3">
              <Col xs={12} md={6} lg={6}>
                <Label className="form-label">Reference No</Label>
                <InputMask
                  mask="NPS/C/DSM/DSM/9999/999"
                  name="referenceNo"
                  className="form-control"
                  placeholder="File Reference Number"
                  type="text"
                  onChange={updateFileValidation.handleChange}
                  onBlur={updateFileValidation.handleBlur}
                  value={updateFileValidation.values.referenceNo || ""}
                  invalid={
                    updateFileValidation.touched.referenceNo &&
                    updateFileValidation.errors.referenceNo
                      ? true
                      : false
                  }
                />
                {updateFileValidation.touched.referenceNo &&
                updateFileValidation.errors.referenceNo ? (
                  <FormFeedback type="invalid">
                    <div>{updateFileValidation.errors.referenceNo}</div>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

            <Row className="m-3">
              <Col xs={12} md={6} lg={6}>
                <Label className="form-label">IR No</Label>
                <InputMask
                  // mask="NPS/C/DSM/DSM/9999/999"
                  name="irNo"
                  className="form-control"
                  placeholder="File IR Number"
                  type="text"
                  onChange={updateFileValidation.handleChange}
                  onBlur={updateFileValidation.handleBlur}
                  value={updateFileValidation.values.irNo || ""}
                  invalid={
                    updateFileValidation.touched.irNo &&
                    updateFileValidation.errors.irNo
                      ? true
                      : false
                  }
                />
                {updateFileValidation.touched.irNo &&
                updateFileValidation.errors.irNo ? (
                  <FormFeedback type="invalid">
                    <div>{updateFileValidation.errors.irNo}</div>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

            <Row className="m-3">
              <Col>
                <Label className="form-label">File Sent On</Label>
                <Input
                  name="dateSent"
                  className="form-control"
                  placeholder="File Sent Date."
                  type="date"
                  onChange={updateFileValidation.handleChange}
                  onBlur={updateFileValidation.handleBlur}
                  value={updateFileValidation.values.dateSent || ""}
                  invalid={
                    updateFileValidation.touched.dateSent &&
                    updateFileValidation.errors.dateSent
                      ? true
                      : false
                  }
                />

                {updateFileValidation.touched.dateSent &&
                updateFileValidation.errors.dateSent ? (
                  <FormFeedback type="invalid">
                    <div>{updateFileValidation.errors.dateSent}</div>
                  </FormFeedback>
                ) : null}
              </Col>

              <Col>
                <Label className="form-label">File Received On</Label>
                <Input
                  name="dateReceived"
                  className="form-control"
                  placeholder="File Received Date"
                  type="date"
                  onChange={updateFileValidation.handleChange}
                  onBlur={updateFileValidation.handleBlur}
                  value={updateFileValidation.values.dateReceived || ""}
                  invalid={
                    updateFileValidation.touched.dateReceived &&
                    updateFileValidation.errors.dateReceived
                      ? true
                      : false
                  }
                />
                {updateFileValidation.touched.dateReceived &&
                updateFileValidation.errors.dateReceived ? (
                  <FormFeedback type="invalid">
                    <div>{updateFileValidation.errors.dateReceived}</div>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

            <Row className="m-3">
              <Col>
                <div className="form-check form-switch form-switch-lg">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizelg"
                    onChange={handleFileOrigin}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customSwitchsizelg"
                  >
                    File created internally by institution
                  </label>
                </div>
              </Col>
            </Row>

            {!isInternalFile ? (
              <Row className="m-3">
                <Col xs={12} md={6} lg={6}>
                  <Label className="form-label">Institution</Label>

                  <Select
                    name="institutions"
                    placeholder="Institution"
                    options={institutions}
                    onChange={handleInstitution}
                    value={
                      fileOriginId > 0
                        ? institutions.filter(
                            (institution) => institution.value === fileOriginId
                          )
                        : ""
                    }
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Input type="hidden" />
          </div>
          <div className="text-center mt-4 mb-4 pb-5">
            <Button onClick={handleConfirm} color="primary">
              {" "}
              Update Details
            </Button>

            {showConfirmMsg && (
              <div>
                <br /> Are you sure want to update this File?{" "}
                <Button type="submit" color="primary">
                  {updateFileValidation.isSubmitting
                    ? "Please wait ..."
                    : "Yes"}
                </Button>
                <Button
                  onClick={handleCancelConfirm}
                  color="danger"
                  style={{ marginLeft: 3 }}
                >
                  No
                </Button>
              </div>
            )}
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Main;
