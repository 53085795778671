import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Area } from "@ant-design/plots";

const MultiAreaChart = ({ loadedData }) => {
  const data = loadedData;
  const config = {
    data,
    xField: 'label',
    yField: 'value',
    seriesField: 'type',
    color: [" #0047AB", "#006D5B", "#800020", "#4B0082", "#D69408", "#708090"],
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
  };
  return <Area {...config} />;
};

export default MultiAreaChart;
