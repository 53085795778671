import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";
import DataEntry from "../Pages/DataEntry";
import QuestionnaireResults from "../Pages/QuestionnaireResults";
import ProgrammeProgress from "../Pages/ProgrammeProgress";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/change-password";
import FileMovement from "../Pages/FileHandling/index"

//Reports
import DataEntryHistory from "../Pages/Reports/DataEntryHistory";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  {path: "/data-entry", component: <DataEntry/>},
  {path: "/data-entry-history", component: <DataEntryHistory/>},
  {path: "/questionnaire-results", component: <QuestionnaireResults/>},
  {path: "/programme-progress", component: <ProgrammeProgress/>},
  {path: "/file-movement", component: <FileMovement/>},


  
  // Profile
  { path: "/change-password", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
