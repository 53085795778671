import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Gauge } from '@ant-design/plots';

const AntGauge = ({ value, numberOfDays, ticks, max }) => {
  const config = {
    percent: value,
    range: {
      ticks: ticks,
      color: ["#006D5B", "#D69408", "#800020"],
      width:12,
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#D0D0D0',
        },
      },
      pin: {
        style: {
          stroke: '#D0D0D0',
        },
      },
    },
    axis: {
      label: {
        formatter(v) {
          return Number(v) * max;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      content: {
        formatter: ({ percent }) => `${numberOfDays} Days`,
      },
      style: {
        fontSize: 40,
      },
    },
  };
  return <Gauge {...config} />;
};

export default AntGauge;