import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import appIcon from "../../../assets/images/apps/app.png";

//i18n
import { withTranslation } from "react-i18next";

const AppsDropdown = () => {
  const [singlebtn, setSinglebtn] = useState(false);

  return (
    <React.Fragment>

      <Dropdown
        isOpen={singlebtn}
        toggle={() => setSinglebtn(!singlebtn)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
 
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ri-apps-2-line"></i>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end">
          <div className="px-lg-2">
            <SimpleBar>
              <Row className="g-0">
                <Col>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      className="dropdown-icon-item"
                      href="https://mof.go.tz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <img src={appIcon} alt="Finance" />
                      MoF
                    </a>
                  </div>
                </Col>

                <Col>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      className="dropdown-icon-item"
                      href="https://www.bot.go.tz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <img src={appIcon} alt="Treasury" />
                      BoT
                    </a>
                  </div>
                </Col>

                <Col>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      className="dropdown-icon-item"
                      href="https://ikulu.go.tz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      <img src={appIcon} alt="BSAAT" />
                      Ikulu
                    </a>
                  </div>
                </Col>
              </Row>
            </SimpleBar>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(AppsDropdown);
