import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";

const LineChart = ({ loadedData }) => {
  const data = loadedData;
  const config = {
    data,
    padding: 'auto',
    xField: 'label',
    yField: 'value',
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
  };
  return <Line {...config} />;
};

export default LineChart;
