import { useCallback } from "react";
import { Input } from "reactstrap";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { surveyJson } from "./surveyQuestionnaire1";
import { postQuestionnaire } from "../../../helpers/backend_helper";

const Questionnaire = ({ questionnaireType }) => {
  const survey = new Model(surveyJson);
  survey.focusFirstQuestionAutomatic = false;

  const alertResults = useCallback((sender) => {
    const results = JSON.stringify(sender.data);
  
    let payload = { results: results, origin: questionnaireType };

    postQuestionnaire(payload)
      .then((res) => {
        if (res.status === 200) {
        } else {
        }
      })
      .catch((err) => {});
  }, []);

  survey.onComplete.add(alertResults);

  return <Survey model={survey} />;
};

export default Questionnaire;
