
const SidebarData = [
  {
    label: "Menu",
    isMainMenu: true,
    module: '',
  },
  {
    label: "Dashboard",
    icon: "mdi mdi-home-variant-outline",
    isHasArrow: true,
    module: '',
    url: "/dashboard",
  },
  {
    label: "File Handling",
    module: 'files',
    isMainMenu: true,
  },
  {
    label: "File Management",
    icon: "mdi mdi-file",
    isHasArrow: true,
    module: 'files',
    url: "/file-movement",
  },
  {
    label: "Statistics And Data Sharing",
    module: 'statistics',
    isMainMenu: true,
  },
  {
    label: "Data Entry Forms",
    icon: "mdi mdi-file",
    isHasArrow: true,
    module: 'statistics',
    url: "/data-entry",
  },
  {
    label: "Reports",
    module: 'statistics',
    isMainMenu: true,
  },
  {
    label: "Data Entry History",
    icon: "mdi mdi-format-page-break",
    module: 'statistics',
    subItem: [
      { sublabel: "Periodic Submissions", link: "/data-entry-history" },
    ],
  },
  {
    label: "Financials and Programmatic",
    module: '',
    isMainMenu: true,
  },
  {
    label: "Questionnaires",
    icon: "mdi mdi-format-page-break",
    isHasArrow: true,
    module: 'questionnaires',
    url: "/questionnaire-results",
  },
  {
    label: "Help and Support",
    module: '',
    isMainMenu: true,
  },
];


export default SidebarData;
