import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RadialBar } from '@ant-design/plots';

const Radial = ({loadedData}) => {
    const data = loadedData

const config = {
    data,
    xField: 'name',
    yField: 'value',
    maxAngle: 270,
    //最大旋转角度,
    radius: 0.8,
    innerRadius: 0.2,
    barStyle: {
      lineCap: 'round',
    },
    tooltip: {
      formatter: (datum) => {
        return {
          name: 'Referrals Sent',
          value: datum.value,
        };
      },
    },
    colorField: 'value',
    color: ({ value }) => {
      if (value > 50) {
        return '#800020';
      } else if (value > 30) {
        return '#4B0082';
      }

      return '#006D5B'; 

    },
  };
  return <RadialBar {...config} />;
};

export default Radial
