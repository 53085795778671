import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/plots';

const GroupedBarChart = ({loadedData}) => {
  const data =  loadedData;
  const config = {
    data,
    isGroup: true,
    xField: 'value',
    yField: 'label',

    /** 自定义颜色 */
    colorField: 'type',
    color:["#006D5B", "#0047AB","#800020", "#4B0082", "#D69408", "#708090"],
    seriesField: 'type',
    marginRatio: 0,
    label: {
      position: 'middle',
      // 'left', 'middle', 'right'

      layout: [
       
        {
          type: 'interval-adjust-position',
        }, 
        {
          type: 'interval-hide-overlap',
        }, 
        {
          type: 'adjust-color',
        },
      ],
    },
  };
  return <Bar {...config} />;
};

export default GroupedBarChart;
