import React from "react";
import Routes from "./Routes/index";

// Import Scss
import "./assets/scss/theme.scss";
import './index.css';

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}

export default App;
