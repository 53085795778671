

export const surveyJson = {
  "title": "Questionnaire for Qualitative Study on Delays and Outcome of Grand Corruption Cases in Tanzania January-September 2023. (General Questionnaire)",
  "pages": [
   {
    "name": "page1",
    "elements": [
     {
      "type": "expression",
      "name": "introductory_note",
      "title": "INTRODUCTION: Thank you for participating in this study. Your insights are crucial for understanding the factors contributing to delays in the investigation, prosecution, and adjudication of the grand corruption cases in Tanzania from January to September 2023. This questionnaire aims to specifically gather your experiences and perspectives on those cases."
     },
     {
      "type": "radiogroup",
      "name": "role",
      "title": "1. What best describes your role?",
      "isRequired": true,
      "choices": [
       "Investigator",
       "Prosecutor",
       "Magistrate"
      ],
      "showOtherItem": true,
      "otherText": "Government Official (Specify)",
      "colCount": 3
     },
     {
      "type": "radiogroup",
      "name": "experience",
      "title": "2. How many years of experience do you have in your current role?",
      "isRequired": true,
      "choices": [
       "Less than one year",
       "1-5 years",
       "6-10 years",
       "More than 10 years"
      ],
      "colCount": 3
     },
     {
      "type": "radiogroup",
      "name": "source_institution",
      "title": "3. What institution do you work for?",
      "isRequired": true,
      "choices": [
       "PCCB",
       "NPS",
       "JoT"
      ],
      "colCount": 3
     },
     {
      "type": "expression",
      "name": "note",
      "title": "NOTE: Key factors contributing to delays in the investigation, prosecution, and adjudication of  grand corruption cases in Tanzania ."
     },
     {
      "type": "expression",
      "name": "investigation",
      "title": "From your perspective, what are the primary factors contributing to delays in the investigation of grand corruption cases? (Please provide specific examples if possible)."
     },
     {
      "type": "checkbox",
      "name": "insufficient_resources",
      "title": "4.1 Insufficient Resources:",
      "isRequired": true,
      "choices": [
       "Limited funding for investigations, i.e. inadequate staffing etc",
       "Lack of specialized forensic equipment, hindering the swift analysis of complex financial transactions.",
       "Inadequate/Limited specialized forensic personnel i.e. handwriting experts, systems analysts, systems forensic auditors",
       "Inadequate working tools – i.e., Motor vehicles; motorcycles.",
       "Competing priorities within law enforcement agencies affecting the allocation of resources to grand corruption cases.",
       "Limited focus on long-term, complex investigations due to a preference for quicker wins."
      ],
      "showOtherItem": true
     },
     {
      "type": "checkbox",
      "name": "case_complexity",
      "title": "4.2 Complexity of Cases:",
      "isRequired": true,
      "choices": [
       "Elaborate financial structures and intricate money trails requiring extensive time and expertise.",
       "Involvement of multiple jurisdictions, leading to coordination challenges among investigative agencies.",
       "Involvement of numerous/multiple complex crimes",
       "Requirement of parallel financial investigations",
       "Lack of specialized skills in investigating intricate and complex cases"
      ],
      "showOtherItem": true
     },
     {
      "type": "checkbox",
      "name": "operational_challenges",
      "title": "4.3 Operational Challenges:",
      "isRequired": true,
      "choices": [
       "Investigation plans inadequately addressing the ingredients of investigated offences.",
       "Delays in obtaining search warrants or court approvals for specific investigative actions.",
       "Cumbersome administrative processes for obtaining necessary approvals and permissions.",
       "Inefficiencies in inter-agency communication and collaboration."
      ],
      "showOtherItem": true
     },
     {
      "type": "checkbox",
      "name": "witness_cooperation_and_prosecution",
      "title": "4.4 Witness Cooperation and Protection:",
      "isRequired": true,
      "choices": [
       "Difficulty in securing cooperation from witnesses due to fear of reprisals- i.e. employee-employer relationship; duty of confidentiality",
       "Delays in implementing witness protection programs,impacting the willingness of individuals to come forward.",
       "Witness dropout."
      ],
      "showOtherItem": true
     },
     {
      "type": "checkbox",
      "name": "political_and_public_pressure",
      "title": "4.5 Political and Public Pressure:",
      "isRequired": true,
      "choices": [
       "External pressures affecting the autonomy of investigative agencies.",
       "Changes in political landscapes leading to shifts in priorities and resource allocation.",
       "Media scrutiny impacting the pace of investigations.",
       "Pressure to demonstrate progress in high-profile cases affecting thoroughness and attention to detail."
      ],
      "showOtherItem": true
     }
    ]
   },
   {
    "name": "page2",
    "elements": [
     {
      "type": "matrix",
      "name": "delay_factors_for_prosecution_in_gc_cases",
      "title": "5. In your experience, what factors contribute to delays in the prosecution of grand corruption cases? Can you highlight any challenges or obstacles faced during this process?",
      "isRequired": true,
      "columns": [
       "Yes",
       "No",
       "N/A"
      ],
      "rows": [
       {
        "value": "witness_care_and_protection",
        "text": "Witness care and protection"
       },
       {
        "value": "witness_intimidation_and_protection",
        "text": "Witness intimidation and protection"
       },
       {
        "value": "witness_drop_outs",
        "text": "Witness drop-outs"
       },
       {
        "value": "absence_of_prosecution_witnesses",
        "text": "Absence of prosecution witnesses"
       },
       {
        "value": "absence_of_defence_witnesses",
        "text": "Absence of defence witnesses"
       },
       {
        "value": "absence_of_defence_counsel",
        "text": "Absence of defence counsel"
       },
       {
        "value": "absence_of_prosecutors",
        "text": "Absence of prosecutors"
       },
       {
        "value": "weak_or_inadequate_legal_frameworks_for_handling_grand_corruption_cases",
        "text": "Weak or inadequate legal frameworks for handling grand corruption cases"
       },
       {
        "value": "investigations_incomplete",
        "text": "Investigations incompletes"
       },
       {
        "value": "court_backlog_and_judicial_capacity",
        "text": "Court backlog and judicial capacity"
       },
       {
        "value": "absence_of_magistrates_judges",
        "text": "Absence of magistrates/Judges"
       },
       {
        "value": "public_pressure",
        "text": "Public pressure"
       },
       {
        "value": "lack_of_specialized_personnel",
        "text": "Lack of specialized personnel i.e. handwriting experts"
       },
       {
        "value": "insufficient_numbers_of_trained_investigators",
        "text": "Insufficient numbers of trained investigators,prosecutors, and judges specializing in handling grand corruption cases"
       },
       {
        "value": "the_use_of_advanced_technologies_in_financial_crimes",
        "text": "The use of advanced technologies in financial crimes"
       },
       {
        "value": "inadequate_protection_for_whistleblowers",
        "text": "Inadequate protection for whistleblowers"
       }
      ]
     },
     {
      "type": "matrix",
      "name": "delay_factors_for_gc_in_adjudication",
      "title": "6. From the adjudication perspective, what are the key factors that lead to delays in grand corruption cases? Are there systemic issues that you have observed in this regard?",
      "isRequired": true,
      "columns": [
       "Yes",
       "No",
       "N/A"
      ],
      "rows": [
       {
        "value": "witness_care_and_protection",
        "text": "Witness care and protection"
       },
       {
        "value": "witness_intimidation_and_protection",
        "text": "Witness intimidation and protection"
       },
       {
        "value": "witness_drop_outs",
        "text": "Witness drop-outs"
       },
       {
        "value": "absence_of_prosecution_witnesses",
        "text": "Absence of prosecution witnesses"
       },
       {
        "value": "absence_of_defence_witnesses",
        "text": "Absence of defence witnesses"
       },
       {
        "value": "absence_of_defence_counsel",
        "text": "Absence of defence counsel"
       },
       {
        "value": "absence_of_prosecutors",
        "text": "Absence of prosecutors"
       },
       {
        "value": "weak_or_inadequate_legal_frameworks_for_handling_grand_corruption_cases",
        "text": "Weak or inadequate legal frameworks for handling grand corruption cases"
       },
       {
        "value": "investigations_incomplete",
        "text": "Investigations incomplete"
       },
       {
        "value": "court_backlog_and_judicial_capacity",
        "text": "Court backlog and judicial capacity"
       },
       {
        "value": "absence_of_magistrates_judges",
        "text": "Absence of magistrates/Judges"
       },
       {
        "value": "public_pressure",
        "text": "Public pressure"
       },
       {
        "value": "lack_of_specialized_personnel_handwriting_experts",
        "text": "Lack of specialized personnel - handwriting experts"
       },
       {
        "value": "lack_of_specialized_personnel_interpreters",
        "text": "Lack of specialized personnel - interpreters"
       },
       {
        "value": "lack_of_specialized_personnel_sign_language_experts",
        "text": "Lack of specialized personnel - sign language experts"
       },
       {
        "value": "insufficient_numbers_of_trained_investigators",
        "text": "Insufficient numbers of trained investigators,prosecutors, and judges specializing in handling grand corruption cases"
       },
       {
        "value": "the_use_of_advanced_technologies_in_financial_crimes",
        "text": "The use of advanced technologies in financial crimes"
       },
       {
        "value": "inadequate_protection_for_whistleblowers",
        "text": "Inadequate protection for whistleblowers"
       }
      ]
     },
     {
      "type": "matrix",
      "name": "impact_of_delays_in_gc_cases",
      "title": "7. How do delays in the processing of grand corruption cases impact the overall effectiveness of anti-corruption measures in Tanzania?",
      "isRequired": true,
      "columns": [
       "Yes",
       "No",
       "N/A"
      ],
      "rows": [
       {
        "value": "demoralize_the_whistleblowing_and_reporting_of_grand_corruption",
        "text": "Demoralize the whistleblowing and reporting of grand corruption"
       },
       {
        "value": "normalization_of_corruption",
        "text": "Normalization of corruption"
       },
       {
        "value": "increase_of_impunity_on_gc",
        "text": "Increase of impunity on GC (Grand Corruption)"
       },
       {
        "value": "decrease_of_referrals_of_gc_allegations_to_leas",
        "text": "Decrease of referrals of GC allegations to LEAs (Law Enforcement Agencies)"
       },
       {
        "value": "ineffective_recovery_of_proceeds_of_crimes",
        "text": "Ineffective recovery of proceeds of crimes"
       },
       {
        "value": "increased_number_of_acquittals_and_discharges_of_accused_persons",
        "text": "Increased number of acquittals and discharges of accused persons"
       },
       {
        "value": "increased_costs_and_expenses_in_handling_gc",
        "text": "Increased costs and expenses in handling GC"
       },
       {
        "value": "exposing_the_government_to_liability_of_paying_compensation_to_persons_acquitted_by_courts",
        "text": "Exposing the government to liability of paying compensation to persons acquitted by courts"
       }
      ]
     },
     {
      "type": "matrix",
      "name": "reasons_for_few_convictions_over_acquittals",
      "title": "8. Most of the decisions on Grand corruption cases show that the accused persons were either acquitted or discharged whereas convictions are extremely few: what do you consider to be the reasons/factors for such acquittals and discharges?",
      "isRequired": true,
      "columns": [
       "Yes",
       "No",
       "N/A"
      ],
      "rows": [
       {
        "value": "lack_of_quality_investigation_of_GC_allegations",
        "text": "(Investigations) - Lack of quality investigation of GC allegations"
       },
       {
        "value": "inadequate_supervision_by_prosecutors_in_investigating_gc_cases",
        "text": "(Investigation) - inadequate supervision by prosecutors in investigating GC cases"
       },
       {
        "value": "insufficient_evidence_for_gc_cases",
        "text": "(Investigation) - insufficient evidence for GC cases"
       },
       {
        "value": "quality_and_relevance_of_evidence_submitted",
        "text": "(Investigation) - quality and relevance of evidence submitted"
       },
       {
        "value": "inadequate_preparation_of_case_files_on_GC",
        "text": "(Prosecution) - inadequate preparation of case files on GC"
       },
       {
        "value": "inadequate_lack_of_preparation_of_evidence",
        "text": "(Prosecution) - inadequate/ lack of preparation of evidence [witnesses]"
       },
       {
        "value": "lack_of_experience_and_skills_in_handling_gc",
        "text": "(Prosecution) - lack of experience and skills in handling GC "
       },
       {
        "value": "-lack_of_specialization_and_expertise_in_handling_gc",
        "text": "(Prosecution) - -lack of specialization and expertise in handling GC "
       },
       {
        "value": "insufficient_time_allocated_for_prosecutors_in_handling_gc",
        "text": "(Prosecution) - insufficient time allocated for prosecutors in handling GC"
       },
       {
        "value": "inadequate_skills_and_expertise_in_handling_gc",
        "text": "(Adjudication) - inadequate skills and expertise in handling GC"
       },
       {
        "value": "inadmissibility_of_evidence_tendered_by_the_prosecutors_in_courts",
        "text": "(Adjudication) - inadmissibility of evidence tendered by the prosecutors in courts"
       },
       {
        "value": "insufficiency_of_evidence_tendered_by_the_prosecutors_in_courts",
        "text": "(Adjudication) - insufficiency of evidence tendered by the prosecutors in courts"
       },
       {
        "value": "irrelevance_of_evidence_tendered_by_the_prosecutors_in_courts",
        "text": "(Adjudication) - irrelevance of evidence tendered by the prosecutors in courts"
       },
       {
        "value": "absence_of_key_witnesses",
        "text": "(Adjudication) - absence of key witnesses"
       }
      ]
     }
    ]
   },
   {
    "name": "page3",
    "elements": [
     {
      "type": "text",
      "name": "recommendations_to_enhance_efficiency",
      "title": "9. Based on your experience, what specific recommendations do you propose to enhance the efficiency of the investigation process for grand corruption cases?",
      "isRequired": true
     },
     {
      "type": "text",
      "name": "recommendations_for_reducing_delays",
      "title": "10. From your perspective, what measures could be implemented to reduce delays in the prosecution of grand corruption cases?",
      "isRequired": true
     },
     {
      "type": "text",
      "name": "recommendations_for_handling_gc_cases",
      "title": "11. Considering adjudication, what evidence-based recommendations would you suggest to improve the efficiency of handling grand corruption cases in Tanzania?",
      "isRequired": true
     },
     {
      "type": "expression",
      "name": "product_recommend",
      "title": "Conclusion: Your valuable insights will contribute to a comprehensive understanding by BSAAT of the challenges and potential solutions related to the delays and outcomes in grand corruption cases in Tanzania. Thank you for your time and input.?"
     }
    ]
   }
  ],
  "showQuestionNumbers": "off"
 }