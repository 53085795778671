import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

const VerticalGroupedBarChart = ({loadedData}) => {
  const data =  loadedData;
  const config = {
    data,
    isGroup: true,
    xField: 'label',
    yField: 'value',
    seriesField: 'type',
    color: [" #0047AB", "#006D5B", "#800020", "#4B0082", "#D69408", "#708090"],
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        }, 
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };
  return <Column {...config} />;
};

export default VerticalGroupedBarChart;
