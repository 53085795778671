const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
}

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
}

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
}

const leftSidebarTypes = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
}

const leftSideBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
}

export {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
}


const prod = {
    url: {
        API_URL:"https://api.bsaat.org"
    }
};

const dev = {
    url: {
        API_URL: "http://127.0.0.1:8000"
    }
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;