import React from 'react';
import {Container} from 'reactstrap';
import {Card, CardContent } from '@mui/material';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Main = () => {
    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={localStorage.getItem("project")} breadcrumbItem="Programme and Financials"/>
                </Container>
                <Card>
                    <CardContent>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <h5 className="card-title">Burn Rate Overview</h5>
                            </div>
                        </div>
                    </CardContent>

                </Card>
            </div>
        </>
    )
}

export default Main;