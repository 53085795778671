import React, { useState, useEffect } from "react";
import { Button, Row, Col, Checkbox } from "reactstrap";
import {
  getDraftDataPosted,
  deleteDraftItem,
} from "../../helpers/backend_helper";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import Spinner from "../../components/Spinner/index";

const Main = ({ categoryId, submissionNo }) => {
  let navigate = useNavigate();
  const [draftData, setDraftData] = useState([]);
  const [draftTotals, setDraftTotals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let nf = new Intl.NumberFormat();

  const fetchDraftData = async () => {
    setIsLoading(true);
    setDraftData([]);

    try {
      await getDraftDataPosted(categoryId).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setDraftData(res["data"]);
          setDraftTotals(res["totals"]);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsLoading(false);
  };


  useEffect(() => {
    fetchDraftData();
  }, [categoryId, submissionNo]);

  
  const handleDelete = async (event, data) => {
    console.log(data);
    await deleteDraftItem(data).then((res) => console.log(res.status));

    fetchDraftData();
  };

  return (
    <>
    {isLoading ? <Spinner /> :
      <table className="table table-hover mb-0">
        <thead>
          <tr>
            <th>#</th>
            <th>Shared Status</th>
            <th>Region</th>
            <th>Period</th>
            <th>Year</th>

            {draftData[0] ? (
              <>
                {Object.keys(draftData[0].data).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
              </>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {draftData.map((item, index) => (
            <tr key={index}>
              <td style={{ width: "2rem" }}>{index + 1}</td>
              <td style={{ width: "5rem" }}>
                {JSON.stringify(item.harmonization_status) === "true" ? (
                  <span className="badge bg-success">
                    {" "}
                    Exposed to other BIs
                  </span>
                ) : (
                  <span className="badge bg-info"> Internal Access Only</span>
                )}
              </td>
              <td style={{ width: "10rem" }}>
                <h6>{item.region}</h6>
              </td>
              <td style={{ width: "10rem" }}>
                <h6>{item.period}</h6>
              </td>
              <td style={{ width: "10rem" }}>
                <h6>{item.period_order ===1 ? `${item.year}/${item.year + 1}` : `${item.year -1}/${item.year}` }</h6>
              </td>

              {Object.keys(item.data).map((key) => {
                return Object.keys(item.data[key]).map((dataKey) => {
                  return Object.keys(item.data[key][dataKey]).map((newKey) => {
                    return (
                      <td style={{ width: "5rem" }} key={newKey}>
                        <h6 key={newKey}>{nf.format(item.data[key][dataKey][newKey])}</h6>
                      </td>
                    );
                  });
                });
              })}

              <td style={{ width: "10rem", textAlign: "center" }}>
                <Link
                  className="btn-sm-danger"
                  onClick={(e) => handleDelete(e, item.records)}
                >
                  Remove
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td style={{ width: "2rem" }}></td>
            <td style={{ width: "5rem" }}></td>
            <td style={{ width: "10rem" }}></td>
            <td style={{ width: "10rem" }}></td>

            {draftTotals ? (
              <>
                <td
                  style={{
                    width: "10rem",
                    fontWeight: "bold",
                    fontSize: "15px",
                    fontStyle: "oblique",
                  }}
                >
                  TOTALS
                </td>
                {draftTotals.map((total, index) => (
                  <td
                    key={index}
                    style={{
                      width: "10rem",
                      fontWeight: "bold",
                      fontSize: "15px",
                      fontStyle: "oblique",
                    }}
                  >
                    {nf.format(total)}
                  </td>
                ))}
              </>
            ) : (
              ""
            )}
          </tr>
        </tfoot>
      </table> }
    </>
  );
};

export default Main;
