import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();
// Gets the logged-in user data from local session

export const getLoggedInUser = () => {
  // const token = localStorage.getItem("accessToken");
  //
  // const user = api.get(url.GET_USER_PROFILE).then()
  //
  // if (user) return JSON.parse(user);
  // return null;

  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const getUserProfile = (data) => api.get(url.GET_USER_PROFILE, data);

//is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data) => {
  return api.create(url.POST_FAKE_REGISTER, data).catch((err) => {
    let message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Login Method
export const postLogin = (data) => api.create_public(url.POST_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data) =>
  api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data) =>
  api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data) =>
  api.create(url.POST_EDIT_PROFILE, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data).catch((err) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Login Method
export const postJwtLogin = (data) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data) =>
  api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data) => api.create(url.SOCIAL_LOGIN, data);

// get Events
export const getEvents = () => api.get(url.GET_EVENTS);

// add Events
export const addNewEvent = (event) => api.create(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = (event) => api.update(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = (event) =>
  api.delete(url.DELETE_EVENT, { headers: { event } });

// get Categories
export const getCategories = (props) => api.get(url.GET_CATEGORIES, props);

// get Data Points
export const getDataPoints = (props) => api.get(url.GET_DATA_POINTS, props);

export const getSpecificRegionData = (props) =>
  api.get(url.GET_PREVIOUS_SPECIFIC_REGION_DRAFT_DATA, props);

//get DataPoints Category Mappings
export const getDataPointCategories = (props) =>
  api.get(url.GET_DATA_POINT_CATEGORIES, props);

//get Draft Data Posted
export const getDraftDataPosted = (categoryId) =>
  api.get(url.GET_DRAFT_DATA, categoryId);

//post Institutional Data
export const postInstitutionalData = (data) =>
  api.create(url.ADD_GET_UPDATE_INSTITUTIONAL_DATA, data);

// get Periods
export const getPeriods = (props) => api.get(url.GET_PERIODS, props);

//get Period Types
export const getPeriodTypes = (props) => api.get(url.GET_PERIOD_TYPES, props);

//get Regions
export const getRegions = (props) => api.get(url.GET_REGIONS, props);

//get previous period
export const getPreviousPeriod = (props) =>
  api.get(url.GET_PREVIOUS_PERIOD, props);

  //get default period type
export const getDefaultPeriodType = (props) =>
  api.get(url.GET_DEFAULT_PERIOD_TYPE, props);

//get Project details
export const getProjectDetails = () => api.get_public(url.GET_PROJECT_DETAILS);

//delete Draft Data item
export const deleteDraftItem = (data) =>
  api.create(url.DELETE_DRAFT_ITEM, data);

//expose Institutional Data
export const exposeInstitutionalData = (data) =>
  api.create(url.EXPOSE_BI_DATA, data);


// file endpoints
export const getFileDetails = (props) => api.get(url.FILES, props);
export const postFileDetails = (props) => api.create(url.POST_FILE, props);
export const updateFileDetails = (props) => api.update(url.UPDATE_FILE, props);

//File Tracker
export const getFileTracker = (props) => api.get(url.GET_FILE_TRACKER, props);
export const updateFileTracker = (props) =>
  api.update(url.UPDATE_FILE_TRACKER, props);

export const createFileTracker = (props) =>
  api.create(url.CREATE_FILE_TRACKER, props);

//File Tracker Log
export const getFileTrackerLog = (props) =>
  api.get(url.GET_FILE_TRACKER_LOG, props);

//Miscelleanous
export const getInvestigationOfficers = (props) =>
  api.get(url.GET_INVESTIGATION_OFFICERS, props);
export const getCoordinators = (props) => api.get(url.GET_COORDINATORS, props);
export const getInstutions = (props) => api.get(url.GET_INSTITUTIONS, props);
export const getFileSummary = (props) => api.get(url.GET_FILE_SUMMARY, props);
export const getOverviewData = (props) => api.get(url.GET_OVERVIEW_DATA, props);
export const getTrendsData = (props) => api.get(url.GET_TRENDS, props);
export const getReasons = (props) => api.get(url.GET_REASONS, props);

export const getSubmittedData = (props) => api.get(url.GET_SUBMITTED_DATA, props);
export const getSubmittedDataAllInstitutions = (props) => api.get(url.GET_SUBMITTED_DATA_ALL_INSTITUTIONS, props);

//Authentication
export const updatePassword = (props) => api.update(url.UPDATE_PASSWORD, props)

//post Questionnaire
export const postQuestionnaire = (data) =>
  api.create_public(url.POST_QUESTIONNAIRE, data);

export const getQuestionnaireResults = (props) => api.get(url.GET_QUESTIONNAIRE_RESULTS, props);