import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { getFileSummary } from "../../../../helpers/backend_helper";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import { OverViewData } from "../../../../CommonData/Data";

const Main = ({
  filterType,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
}) => {
  
  let navigate = useNavigate();

  const [disciplinaryAction, setDisciplinaryAction] = useState([])
  const [chargedInCourt, setChargedInCourt] = useState([])
  const [closed, setClosed] = useState([])
  const [furtherClarification, setFurtherClarification] = useState([])

  const [labels, setLabels] = useState([]);

  const fetchFileSummary = () => {
    setDisciplinaryAction([]);
    setChargedInCourt([]);
    setClosed([]);


    let selectedFilters = {
      filterType: filterType,
      startDate: startDate,
      endDate: endDate,
      reason: ''
    };

    try {
      getFileSummary(selectedFilters).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          
          setDisciplinaryAction(
            res["file_summary_series_data"]["total_files_disciplinary_action_list"]
          );
          setChargedInCourt(
            res["file_summary_series_data"][
              "total_files_charged_in_court_list"
            ]
          );
          setClosed(
            res["file_summary_series_data"][
              "total_files_closed_list"
            ]
          );
          setFurtherClarification(res["file_summary_series_data"]["total_files_for_further_clarification_list"])

          setLabels(res["file_summary_series_data"]["labels"]);

          handleStartDateChange(res.resolved_filters.start_date);
          handleEndDateChange(res.resolved_filters.end_date);
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  useEffect(() => {
    fetchFileSummary();
  }, [filterType, startDate, endDate]);


  const LineColumnAreaData = {
    series: [
      {
        name: "Disciplinary Action",
        type: "area",
        data: disciplinaryAction ,
      },
      {
        name: "Charged In Coourt",
        type: "area",
        data: chargedInCourt,
      },
      {
        name: "Closed",
        type: "column",
        data: closed,
      },
      {
        name: "Further Clarification",
        type: "column",
        data: furtherClarification,
      },
    ],
    options: {
      chart: {
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 0.5, 1],
        curve: "smooth",
        dashArray: [0, 8, 5],
      },
      plotOptions: {
        bar: {
          columnWidth: "18%",
        },
      },
      colors: ["#0ac074", "#4aa3ff",'#ff3d60',"#fcb92c"],
    
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: labels,
      markers: {
        size: 0,
      },
      legend: {
        offsetY: 11,
      },
      xaxis: {
        type: "month",
      },
      yaxis: {
        title: {
          text: "Number",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          },
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mr-3">
            <div className="flex-grow-1">
              <h5 className="card-title">Overall File Dispatch Summary</h5>
            </div>
          </div>
          <div></div>
          <ReactApexChart
            options={LineColumnAreaData.options}
            series={LineColumnAreaData.series}
            type="line"
            height="350"
            stacked="false"
            className="apex-charts"
          />
        </CardBody>
        <CardBody className="border-top">
          <div className="text-muted text-center">
            <Row>
              {OverViewData.map((item, key) => (
                <Col md={4} key={key} className="border-end">
                  <div>
                    <p className="mb-2">
                      <i
                        className={
                          "mdi mdi-circle font-size-12 me-1 text-" + item.color
                        }
                      ></i>{" "}
                      {item.title}
                    </p>
                    {/* <h5 className="font-size-16 mb-0">{item.count} <span className="text-success font-size-12"><i className="mdi mdi-menu-up font-size-14 me-1"></i>{item.percentage} %</span></h5> */}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Main;
