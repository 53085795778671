import React, { useEffect, useState, Suspense } from "react";
import {
  Container,
  Row,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Alert,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FileSummary from "./FileSummary/index";
import Overview from "./Overview/index";
import Trends from "./Trends/index";
import { DatePicker } from "antd";
import moment from "moment";
import LineColumnArea from "./ChartTypes/LineColumnArea";
import { Tabs } from "antd";
import {
  FolderOpenOutlined,
  DashboardOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import {
  getPeriods,
  getRegions,
  getPreviousPeriod,
} from "../../helpers/backend_helper";
import Select, { components } from "react-select";
import Spinner from "../../components/Spinner/index";
//redux
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  // File Summary
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [startYear, setStartYear] = useState("");
  // const [endYear, setEndYear] = useState("");
  const [filterType, setFilterType] = useState("six_months");
  const [startDateDetails, setStartDateDetails] = useState("");
  const [endDateDetails, setEndDateDetails] = useState("");
  const { RangePicker } = DatePicker;
  const [customOptionSelected, setCustomOptionSelected] = useState(false);
  const [tabNo, setTabNo] = useState("1");
  const [periods, setPeriods] = useState([]);
  const [regions, setRegions] = useState([]);
  const [defaultPeriod, setDefaultPeriod] = useState({});
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);

  //Filters
  const [filterOptions, setFilterOptions] = useState([]);

  // Overview
  const [selectedPeriodOptions, setSelectedPeriodOptions] = useState([]);
  const [selectedRegionOptions, setSelectedRegionOptions] = useState([]);
  const [filterPeriod, setFilterPeriod] = useState([]);
  const [filterPeriodLabel, setFilterPeriodLabel] = useState("");
  const [filterRegion, setFilterRegion] = useState([]);
  const [filterRegionLabel, setFilterRegionLabel] = useState("");
  const [filterYearFrom, setFilterYearFrom] = useState("");
  const [filterYearTo, setFilterYearTo] = useState("");
  const [filterYearLabel, setFilterYearLabel] = useState("");

  const [selectedReason, setSelectedReason] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);

  const tracksFiles = localStorage.getItem("tracksFiles") === "true";
  const visualizesData = localStorage.getItem("visualizesData") === "true";

  document.title = `${localStorage.getItem("project")} | ${localStorage.getItem(
    "details"
  )}`;

  // Glabal variables declarations
  let firstName = localStorage.getItem("firstName");
  let lastName = localStorage.getItem("lastName");

  const fetchPeriods = async () => {
    setPeriods([]);

    try {
      await getPeriods(null).then((res) => {
        res.map((data) => {
          const value = data.order;
          const label = data.description;

          let dataObject = { value: value, label: label };

          setPeriods((current) => [...current, dataObject]);
        });
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchRegions = async () => {
    setRegions([]);

    try {
      await getRegions(null).then((res) => {
        res.map((data) => {
          const value = data.id;
          const label = data.description;

          let dataObject = { value: value, label: label };

          setRegions((current) => [...current, dataObject]);
        });
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchPreviousPeriod = async () => {
    try {
      await getPreviousPeriod(null).then((res) => {
        setDefaultPeriod(res);
        setSelectedPeriod(res.value);

        //pass previous period as default value for the dashboard
        setFilterPeriodLabel(res.label);
        setFilterPeriod([res.order]);
        if (
          res.order === 1 ||
          res.order === 2 ||
          res.order === 3 ||
          res.order === 4
        ) {
          setFilterYearFrom(new Date().getFullYear());
        }

        setFilterYearLabel(res.year_range);
        setFilterYearTo(new Date().getFullYear());
        setFilterRegionLabel("All Regions");
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const items = [
    {
      icon: <DashboardOutlined />,
      key: "1",
      label: `Quarter Overview`,
      module: "statistics",
    },
    {
      icon: <LineChartOutlined />,
      key: "3",
      label: `General Trends`,
      module: "statistics",
    },
    {
      icon: <FolderOpenOutlined />,
      key: "2",
      label: `File Handling`,
      module: "files",
    },
  ];

  function handleCustomFilterType(filterType) {
    setFilterType(filterType);
    setCustomOptionSelected(false);
  }

  const handleDateRangeChange = (value, dateString) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);

    setFilterType("custom");
  };

  const handleYearRangeChange = (value, dateString) => {
    setFilterYearFrom(dateString[0]);
    setFilterYearTo(dateString[1]);

    setFilterYearLabel(dateString[0] + "-" + dateString[1]);

    setFilterPeriod([]);
    setFilterRegion([]);

    selectedPeriodOptions.map((item) =>
      setFilterPeriod((oldState) => [...oldState, item.value])
    );

    selectedRegionOptions.map((item) =>
      setFilterRegion((oldState) => [...oldState, item.value])
    );

    if (filterType === "custom" && filterRegionLabel === "") {
      setFilterRegionLabel("All Regions");
    }

    if (filterType === "custom" && filterPeriodLabel === "") {
      setFilterPeriodLabel("All Periods");
    }
  };

  const handleStartDateChange = (details) => {
    setStartDateDetails(details);
  };

  const handleEndDateChange = (details) => {
    setEndDateDetails(details);
  };

  const handleMultiplePeriodSelection = (options) => {
    setSelectedPeriodOptions([]);

    setSelectedPeriodOptions(options);
    let label = "";
    options.map((item) => {
      if (label === "") {
        label = `${item.label}`;
      } else {
        label = `${label},${item.label}`;
      }
    });

    setFilterPeriodLabel(label);
  };

  const handleMultipleRegionSelection = (options) => {
    setSelectedRegionOptions([]);

    setSelectedRegionOptions(options);
    let label = "";
    options.map((item) => {
      if (label === "") {
        label = `${item.label}`;
      } else {
        label = `${label},${item.label}`;
      }
    });

    setFilterRegionLabel(label);
  };

  const handlePeriodChange = (periodOrderId, yearFrom, yearTo, label) => {
    if (periodOrderId === 5) {
      setFilterPeriodLabel("");
      setFilterYearLabel("");
      setFilterYearFrom("");
      setFilterYearTo("");
      setFilterType("custom");

      setCustomOptionSelected(!customOptionSelected);
    } else {
      let period = periods.find((x) => x.value === periodOrderId);

      setFilterYearFrom(yearFrom);
      setFilterYearTo(yearTo);
      setFilterPeriod([periodOrderId]);
      setFilterYearLabel(label);

      if (period) {
        setFilterPeriodLabel(period.label);
        setCustomOptionSelected(false);

        setFilterRegionLabel("All Regions");
      }
    }
  };

  const handleFilterOptions = (state) => {
    setFilterOptions([]);
    setFilterOptions(state);
  };

  // Function to open documentation on a separate tab
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  // Function to update a change in the tabs from the overview tabs to file handling
  const onTabChange = (key) => {
    setTabNo(key);
  };

  const handleReasonChange = (event) => {
    setSelectedReason(event.value);
  };

  const handleLoading = (state) => {
    setIsLoaded(state);
  };

  //Reasons
  const reasons = [
    {
      value: "preliminary_investigation",
      label: "Preliminary Investigation",
      type: "coordinator",
    },
    {
      value: "further_investigation",
      label: "Further Investigation",
      type: "coordinator",
    },
    {
      value: "legal_opinion",
      label: "Legal Opinion",
      type: "coordinator",
    },
    {
      value: "disciplinary_action",
      label: "Disciplinary Action",
      type: "registry",
    },
    {
      value: "charged_in_court",
      label: "Charged In Court",
      type: "registry",
    },
    {
      value: "closed_no_further_action",
      label: "Closed - No Further Action",
      type: "registry",
    },
    {
      value: "closed_no_offence_detected",
      label: "Closed - No Offence Detected",
      type: "registry",
    },
    {
      value: "closed_civil",
      label: "Closed - Civil",
      type: "registry",
    },
  ];

  useEffect(() => {
    fetchPeriods();
  }, []);

  useEffect(() => {
    fetchPreviousPeriod();
  }, []);

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    if (!visualizesData && tracksFiles) {
      setTabNo("2");
    }
  }, []);

  const { error } = useSelector((state) => ({
    error: true,
  }));

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={localStorage.getItem("project")}
            breadcrumbItem="Dashboard"
          />
        </Container>

        <Card>
          <CardBody>
            <div className="mb-4">
              <Row>
                <Col xs={12}>
                  {/* <Button
                    color="success"
                    onClick={() => {
                      openInNewTab(
                        "http://139.144.69.159:8088/superset/welcome/"
                      );
                    }}
                  >
                    <i className="fas fa-line-chart"></i> Advanced Analytics
                  </Button> */}
                </Col>
              </Row>
            </div>
            <Row>
              <div className="flex-shrink-0">
                <strong>
                  Welcome, {firstName} {lastName}{" "}
                </strong>
              </div>
            </Row>

            <Row>
              <div className="flex-shrink-0">
                <Row>
                  <Tabs
                    defaultActiveKey="1"
                    items={items
                      .filter((item) => {
                        if (visualizesData && tracksFiles) {
                          return (
                            item.module === "statistics" ||
                            item.module === "files"
                          );
                        } else if (!visualizesData && tracksFiles) {
                          return item.module === "files";
                        } else if (visualizesData && !tracksFiles) {
                          return item.module === "statistics";
                        }
                      })
                      .map((item, index) => {
                        // const id = String(index + 1);
                        const id = String(item.key);
                        return {
                          label: (
                            <span>
                              {item.icon}
                              {item.label}
                            </span>
                          ),
                          key: id,
                        };
                      })}
                    onChange={onTabChange}
                  />
                </Row>
              </div>
            </Row>
          </CardBody>
        </Card>

        {tabNo === "1" && visualizesData ? (
          <>
            <Card>
              <CardBody>
                {" "}
                <div className="flex-shrink-0 mb-2">
                  <label>Filters:</label>
                  {filterOptions.map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      className="btn btn-soft-secondary btn-sm me-1"
                      defaultChecked
                      onClick={() =>
                        handlePeriodChange(
                          item.order,
                          item.year_from,
                          item.year_to,
                          item.label
                        )
                      }
                    >
                      {item.label}
                    </button>
                  ))}
                  <button
                    type="button"
                    className="btn btn-soft-secondary btn-sm me-1"
                    onClick={() => handlePeriodChange(5)}
                  >
                    CUSTOM
                  </button>

                  {customOptionSelected ? (
                    <div>
                      <Row>
                        <Col
                          xs={12}
                          md={3}
                          lg={3}
                          style={{ marginTop: "20px" }}
                        >
                          <Select
                            isMulti
                            name="period"
                            placeholder="Select Period..."
                            options={periods}
                            onChange={handleMultiplePeriodSelection}
                          />
                        </Col>

                        <Col
                          xs={12}
                          md={3}
                          lg={3}
                          style={{ marginTop: "20px" }}
                        >
                          <Select
                            isMulti
                            name="region"
                            placeholder="Select Region..."
                            options={regions}
                            onChange={handleMultipleRegionSelection}
                          />
                        </Col>
                        <Col
                          xs={12}
                          md={3}
                          lg={3}
                          style={{ marginTop: "20px" }}
                        >
                          <RangePicker
                            picker="year"
                            onChange={(value, dateString) =>
                              handleYearRangeChange(value, dateString)
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="ml-2 mt-3 mb-3">
                    <h1>
                      Data filtered for{" "}
                      {filterRegionLabel
                        ? filterRegionLabel + " and "
                        : "............................"}{" "}
                      {filterPeriodLabel
                        ? filterPeriodLabel
                        : "............................"}{" "}
                      {filterYearLabel
                        ? filterYearLabel
                        : "............................"}
                    </h1>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Row>
              {isLoaded ? (
                <Col xl={12} sm={12}>
                  <Overview
                    filterPeriod={filterPeriod}
                    filterRegion={filterRegion}
                    filterYearFrom={filterYearFrom}
                    filterYearTo={filterYearTo}
                    handleLoading={handleLoading}
                    handleFilterOptions={handleFilterOptions}
                  />
                </Col>
              ) : (
                <Spinner />
              )}
            </Row>
          </>
        ) : tabNo === "2" && tracksFiles ? (
          <>
            <Card>
              <CardBody>
                {" "}
                <div className="flex-shrink-0 mb-2">
                  <label>Filters:</label>
                  <button
                    type="button"
                    className="btn btn-soft-secondary btn-sm me-1"
                    defaultChecked
                    onClick={() => handleCustomFilterType("one_month")}
                  >
                    CURRENT MONTH
                  </button>
                  <button
                    type="button"
                    className="btn btn-soft-primary btn-sm me-1"
                    onClick={() => handleCustomFilterType("three_months")}
                  >
                    3M
                  </button>
                  <button
                    type="button"
                    className="btn btn-soft-secondary btn-sm me-1"
                    onClick={() => handleCustomFilterType("six_months")}
                  >
                    6M
                  </button>
                  <button
                    type="button"
                    className="btn btn-soft-secondary btn-sm me-1"
                    onClick={() => handleCustomFilterType("one_year")}
                  >
                    1Y
                  </button>
                  <button
                    type="button"
                    className="btn btn-soft-secondary btn-sm me-1"
                    onClick={() => {
                      setStartDateDetails("");
                      setEndDateDetails("");
                      setCustomOptionSelected(!customOptionSelected);
                    }}
                  >
                    CUSTOM
                  </button>

                  {customOptionSelected ? (
                    <Row>
                      <Col xs={12} md={3} lg={3} style={{ marginTop: "20px" }}>
                        <Select
                          name="reasons"
                          placeholder="Reasons"
                          options={reasons.filter(
                            (item) => item.type === "registry"
                          )}
                          onChange={handleReasonChange}
                        />
                      </Col>

                      <Col xs={12} md={3} lg={3} style={{ marginTop: "20px" }}>
                        <RangePicker
                          onChange={(value, dateString) =>
                            handleDateRangeChange(value, dateString)
                          }
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}

                  <div className="ml-2 mt-3 mb-3">
                    <h1>
                      Data filtered from{" "}
                      {startDateDetails
                        ? moment(startDateDetails).format("LL")
                        : "............................"}{" "}
                      to{" "}
                      {endDateDetails
                        ? moment(endDateDetails).format("LL")
                        : "............................"}
                    </h1>
                  </div>
                </div>
                <Suspense
                  fallback={
                    <div>
                      <p>Loading data...</p>
                    </div>
                  }
                >
                  <FileSummary
                    filterType={filterType}
                    startDate={startDate}
                    endDate={endDate}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    reason={selectedReason}
                  />
                </Suspense>
              </CardBody>
            </Card>

            <Row>
              <Suspense
                fallback={
                  <div>
                    <p>Loading data...</p>
                  </div>
                }
              >
                <LineColumnArea
                  filterType={filterType}
                  startDate={startDate}
                  endDate={endDate}
                  handleStartDateChange={handleStartDateChange}
                  handleEndDateChange={handleEndDateChange}
                />
              </Suspense>
            </Row>
          </>
        ) : tabNo === "3" && visualizesData ? (
          <Row>
            {isLoaded ? (
              <Col xl={12} sm={12}>
                <Trends />
              </Col>
            ) : (
              <Spinner />
            )}
          </Row>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Dashboard;
