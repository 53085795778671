import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container fluid ={true}>
          <Row>
            <Col align="center" sm={12}>Copyright © {new Date().getFullYear()} - {localStorage.getItem("project")}. (VERSION 2.0.0)</Col>
          </Row>
        </Container>
      </footer>
    </>

  );
}

export default Footer;