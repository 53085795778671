import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Label,
  Button,
  FormFeedback,
  Form,
  Col,
  CardTitle,
  CardBody,
  Alert,
} from "reactstrap";
import InputMask from "react-input-mask";
import { Card, CardContent } from "@mui/material";
import { useFormik } from "formik";
import {
  getInstutions,
  postFileDetails,
} from "../../../helpers/backend_helper";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";

const Main = ({ handleReturn, handleReloadFiles }) => {
  const navigate = useNavigate();
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [loadedFileTracker, setLoadedFileTracker] = useState(0);
  const [selectedInstitution, setSelectedInstitution] = useState("");
  const [isInternalFile, setIsInternalFile] = useState(false);
  const [errorMessage, setErrorMessage]  = useState(false);

  // Submission confirmation
  const handleConfirm = () => {
    if (!showConfirmMsg) {
      setShowConfirmMsg(true);
    }
  };

  const handleCancelConfirm = () => {
    setShowConfirmMsg(false);
  };

  // New file
  const newFileValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      referenceNo: "",
      irNo: "",
      dateSent: "",
      dateReceived: "",
    },
    validationSchema: Yup.object({
      referenceNo: Yup.string().required("Please enter reference no"),
      irNo: Yup.string().required("Please enter IR no"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      values.originId = selectedInstitution;
      values.isInternalFile = isInternalFile;

      postFileDetails(values).then((res) => {

        if (res.status === 200) {
          setShowAlert(true);
          setShowConfirmMsg(false);

          setTimeout(() => {
            setShowAlert(false);
          }, 2000);

          setSubmitting(false);
          setLoadedFileTracker(loadedFileTracker + 1);
          resetForm();
    
          handleReloadFiles();
        } else {
          setShowError(true);

          setTimeout(() => {
            setShowError(false);
          }, 2000);
        }
      }).catch(err => {
        setErrorMessage("Request Failed. File already created.");
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
      });

    },
  });

  // Fetch data from API calls
  const fetchInstitutions = () => {
    try {
      getInstutions(null).then((res) => {
        if (res.status === false) {
          navigate("/logout");
        } else {
          res.map((res) => {
            setInstitutions((data) => [
              ...data,
              { value: res.id, label: `${res.description}` },
            ]);
          });
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  const handleFileOrigin = (event) => {
    setIsInternalFile(!isInternalFile);
  };

  // React Hooks
  useEffect(() => {
    fetchInstitutions();
  }, []);

  const handleInstitution = (event) => {
    setSelectedInstitution(event.value);
  };

  return (
    <Card>
      <CardTitle style={{ margin: "20px" }}>Create New File</CardTitle>
      {showAlert ? (
        <Alert color="success">
          <div>File successfully saved.</div>
        </Alert>
      ) : null}
      {showError ? (
        <Alert color="danger">
          <div>{errorMessage}</div>
        </Alert>
      ) : null}
      <CardBody>
        <div style={{ margin: "20px" }}>
          <Link className="btn btn-primary" onClick={handleReturn}>
            <i className="fas fa-reply me-1 align-middle"> </i> Go back
          </Link>
        </div>
      </CardBody>
      <CardBody>
        <Form
          className="form-group"
          onSubmit={(e) => {
            e.preventDefault();
            newFileValidation.handleSubmit();
            return false;
          }}
        >
          <div className="form-group">
            <Row className="m-3">
              <Col xs={12} md={6} lg={6}>
                <Label className="form-label">Reference No</Label>
                <InputMask
                  mask="NPS/C/DSM/DSM/9999/999"
                  name="referenceNo"
                  className="form-control"
                  placeholder="File Reference Number"
                  type="text"
                  onChange={newFileValidation.handleChange}
                  onBlur={newFileValidation.handleBlur}
                  value={newFileValidation.values.referenceNo || ""}
                  invalid={
                    newFileValidation.touched.referenceNo &&
                    newFileValidation.errors.referenceNo
                      ? true
                      : false
                  }
                />
                {newFileValidation.touched.referenceNo &&
                newFileValidation.errors.referenceNo ? (
                  <FormFeedback type="invalid">
                    <div>{newFileValidation.errors.referenceNo}</div>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

            <Row className="m-3">
              <Col>
                <div className="form-check form-switch form-switch-lg">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizelg"
                    onChange={handleFileOrigin}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customSwitchsizelg"
                  >
                    File created for internal use
                  </label>
                </div>
              </Col>
            </Row>
   
            {!isInternalFile ? (
              <>
              <Row className="m-3">
              <Col xs={12} md={6} lg={6}>
                <Label className="form-label">IR No</Label>
                <InputMask
                  // mask="NPS/C/DSM/DSM/9999/999"
                  name="irNo"
                  className="form-control"
                  placeholder="File IR Number"
                  type="text"
                  onChange={newFileValidation.handleChange}
                  onBlur={newFileValidation.handleBlur}
                  value={newFileValidation.values.irNo || ""}
                  invalid={
                    newFileValidation.touched.irNo &&
                    newFileValidation.errors.irNo
                      ? true
                      : false
                  }
                />
                {newFileValidation.touched.irNo &&
                newFileValidation.errors.irNo ? (
                  <FormFeedback type="invalid">
                    <div>{newFileValidation.errors.irNo}</div>
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

                <Row className="m-3">
                  <Col xs={12} md={6} lg={6}>
                    <Label className="form-label">File Sent On</Label>
                    <Input
                      name="dateSent"
                      className="form-control"
                      placeholder="File Sent Date."
                      type="date"
                      onChange={newFileValidation.handleChange}
                      onBlur={newFileValidation.handleBlur}
                      value={newFileValidation.values.dateSent || ""}
                      invalid={
                        newFileValidation.touched.dateSent &&
                        newFileValidation.errors.dateSent
                          ? true
                          : false
                      }
                    />

                    {newFileValidation.touched.dateSent &&
                    newFileValidation.errors.dateSent ? (
                      <FormFeedback type="invalid">
                        <div>{newFileValidation.errors.dateSent}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>

                <Row className="m-3">
                  <Col xs={12} md={6} lg={6}>
                    <Label className="form-label">File Received On</Label>
                    <Input
                      name="dateReceived"
                      className="form-control"
                      placeholder="File Received Date"
                      type="date"
                      onChange={newFileValidation.handleChange}
                      onBlur={newFileValidation.handleBlur}
                      value={newFileValidation.values.dateReceived || ""}
                      invalid={
                        newFileValidation.touched.dateReceived &&
                        newFileValidation.errors.dateReceived
                          ? true
                          : false
                      }
                    />
                    {newFileValidation.touched.dateReceived &&
                    newFileValidation.errors.dateReceived ? (
                      <FormFeedback type="invalid">
                        <div>{newFileValidation.errors.dateReceived}</div>
                      </FormFeedback>
                    ) : null}
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            {!isInternalFile ? (
              <Row className="m-3">
                <Col xs={12} md={6} lg={6}>
                  <Label className="form-label">File Origin</Label>

                  <Select
                    name="institutions"
                    placeholder="Institution"
                    options={institutions}
                    onChange={handleInstitution}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}

            {/* <Input type="hidden" /> */}
          </div>
          <div className="text-center mt-4 mb-3 pb-5">
            <Button onClick={handleConfirm} color="success">
              {" "}
              Save File
            </Button>

            {showConfirmMsg && (
              <div>
                <br /> Are you sure want to save this File?{" "}
                <Button type="submit" color="success">
                  {newFileValidation.isSubmitting ? "Please wait ..." : "Yes"}
                </Button>
                <Button
                  onClick={handleCancelConfirm}
                  className="btn btn-danger"
                  style={{ marginLeft: 3 }}
                >
                  No
                </Button>
              </div>
            )}
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default Main;
