import {call, put, takeEvery, takeLatest} from "redux-saga/effects";

// Login Redux States
import {LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN} from "./actionTypes";
import {apiError,login, loginSuccess, logoutUserSuccess} from "./actions";

//Include Both Helper File with needed methods
import {getFirebaseBackend} from "../../../helpers/firebase_helper";
import {
    postLogin,
    postJwtLogin,
    postSocialLogin,
} from "../../../helpers/backend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({payload: {user, history}}) {
    try {
        const response = yield call(postLogin, {
            username: user.email,
            password: user.password,
        });

        localStorage.setItem("authUser", JSON.stringify(response));
        localStorage.setItem("accessToken", response["access"]);
        localStorage.setItem("refreshToken", response["refresh"]);

        yield put(loginSuccess(response));

        history("/dashboard");
    } catch (error) {
        let errorMessage =  "Login failed. Please check your email and password"
        yield put(apiError(errorMessage));
    }
}

function* logoutUser() {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("trackFiles");
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const response = yield call(fireBaseBackend.logout);
            yield put(logoutUserSuccess(LOGOUT_USER, response));
        } else {
            yield put(logoutUserSuccess(LOGOUT_USER, true));
        }
    } catch (error) {
        yield put(apiError(LOGOUT_USER, error));
    }
}

function* socialLogin({payload: {data, history, type}}) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend();
            const response = yield call(fireBaseBackend.socialLoginUser, data, type);
            localStorage.setItem("authUser", JSON.stringify(response));
            yield put(loginSuccess(response));
        } else {
            const response = yield call(postSocialLogin, data);
            localStorage.setItem("authUser", JSON.stringify(response));
            yield put(loginSuccess(response));
        }
        history("/dashboard");
    } catch (error) {
        yield put(apiError(error));
    }
}

function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser);
    yield takeLatest(SOCIAL_LOGIN, socialLogin);
    yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
