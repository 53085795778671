import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Label,
  Form,
  TabContent,
  TabPane,
  Col,
  CardTitle,
  Nav,
  CardBody,
  NavItem,
  NavLink,
  Modal,
} from "reactstrap";
import { Card } from "@mui/material";
import { useFormik } from "formik";
import {
  postFileDetails,
  updateFileTracker,
  getFileTracker,
} from "../../../helpers/backend_helper";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";
import DataTable from "react-data-table-component";
import classnames from "classnames";
import Select from "react-select";
import Spinner from "../../../components/Spinner/index";
import { Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";

const Main = ({
  role,
  reasons,
  offences,
  registryFileStatus,
  handleNewFile,
  handleUpdateFile,
  handleReloadFiles,
  coordinators,
  institutions,
  investigationOfficers,
  regions,
  handleShowFileTracker,
}) => {
  const navigate = useNavigate();
  let userId = localStorage.getItem("userId");

  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  const [registryModalBackdrop, setRegistryModalBackdrop] = useState(false);
  const [coordinatorModalBackdrop, setCoordinatorModalBackdrop] =
    useState(false);
  const [investigationModalBackdrop, setInvestigationModalBackdrop] =
    useState(false);

  const [referenceNo, setReferenceNo] = useState();
  const [fileTrackerId, setFileTrackerId] = useState();
  const [fileId, setFileId] = useState();

  const [selectedAssignedOfficer, setSelectedAssignedOfficer] = useState("");
  const [selectedFileStatus, setSelectedFileStatus] = useState("");
  const [selectedInstitution, setSelectedInstitution] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCoordinator, setSelectedCoordinator] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [chargedInCourt, setChargedInCourt] = useState(false);
  const [selectedOffence, setSelectedOffence] = useState("");
  const [moreOffenceDetails, setMoreOffenceDetails] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);
  const [hasBranches, setHasBranches] = useState(false);
  const [bringUpDate, setBringUpDate] = useState("");
  const [savingStatus, setSavingStatus] = useState("Save Changes");

  const [fileTracker, setFileTracker] = useState([]);
  const [loadedFileTracker, setLoadedFileTracker] = useState(0);
  const [fileTrackerLoaded, setFileTrackerLoaded] = useState(false);

  //Tab states
  const [customActiveTab, setcustomActiveTab] = useState("1");

  // General events
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  // Formik form events

  // New file
  const fileMovementValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      referenceNo: "",
      dateSent: "",
      dateReceived: "",
    },
    validationSchema: Yup.object({
      referenceNo: Yup.string().required("Please enter reference no"),
      dateSent: Yup.string().required("Please enter date time sent"),
      dateReceived: Yup.string().required("Please enter date time received"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      values.institutionId = selectedInstitution;

      postFileDetails(values).then((res) => {
        if (res.status === 200) {
          setShowAlert(true);
          setShowConfirmMsg(false);

          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        } else {
          setShowError(true);

          setTimeout(() => {
            setShowError(false);
          }, 2000);
        }
      });

      setSubmitting(false);
      resetForm();
      handleReloadFiles();
    },
  });

  // Modal form
  const AssignFileValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      referenceNo: { referenceNo },
    },
    validationSchema: Yup.object({
      referenceNo: Yup.string().required("Please enter reference no"),
    }),
    onSubmit: (values) => {
      postFileDetails(values).then((res) => {
        if (res.status === 200) {
          setShowAlert(true);
          setShowConfirmMsg(false);

          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        } else {
          setShowError(true);

          setTimeout(() => {
            setShowError(false);
          }, 2000);
        }
      });
    },
  });

  const handleAssignFile = (e, modal, fileTrackerId, fileId, referenceNo) => {
    e.preventDefault();

    setFileTrackerId(fileTrackerId);
    setFileId(fileId);
    setReferenceNo(referenceNo);

    setModalState(modal);
  };

  const setModalState = (modal) => {
    switch (modal) {
      case "registryModalBackdrop":
        return setRegistryModalBackdrop(!registryModalBackdrop);
      case "coordinatorModalBackdrop":
        return setCoordinatorModalBackdrop(!coordinatorModalBackdrop);
      case "investigationModalBackdrop":
        return setInvestigationModalBackdrop(!investigationModalBackdrop);
      default:
        return setRegistryModalBackdrop(!registryModalBackdrop);
    }
  };

  // Submit File Tracker Updates
  const handleUpdateFileTracker = (modal) => {
    setSavingStatus("Please Wait...");

    let obj = {
      fileTrackerId: fileTrackerId,
      fileId: fileId,
      fileStatus: selectedFileStatus,
      assignedOfficerId: selectedAssignedOfficer,
      coordinatorId: selectedCoordinator,
      institutionId: selectedInstitution,
      bringUpDate: bringUpDate,
      selectedReason: selectedReason,
      selectedRegion: selectedRegion,
      offence: selectedOffence,
      moreDetails: moreOffenceDetails,
    };

    updateFileTracker(obj).then((res) => {
      if (res.status === 200) {
        setShowAlert(true);
        setShowConfirmMsg(false);
        handleReloadFileTracker();

        // disable other modal forms from other tabs
        setRegistryModalBackdrop(false);
        setCoordinatorModalBackdrop(false);
        setInvestigationModalBackdrop(false);

        // disable previous selected options from other modal forms in registry, coodinator, investigation and partner institutions
        setSelectedAssignedOfficer("");
        setSelectedCoordinator("");
        setSelectedInstitution("");

        setSavingStatus("Save Changes");

        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      } else {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 2000);
      }
    });

    // setTimeout(() => {
    //   setSavingStatus("Save Changes");
    // }, 2000);
  };

  const handleBringUpDate = (event) => {
    setBringUpDate(event.target.value);
  };

  const handleReloadFileTracker = () => {
    setLoadedFileTracker(loadedFileTracker + 1);
    fetchFileTracker();
  };

  // File status per file movement stage
  const coordinatorFileStatus = [
    {
      value: "assigned_to_investigation_officer",
      label: "File assigned to Investigation Officer",
    },
    {
      value: "returned_to_investigation_officer",
      label: "File returned to Investigation Officer",
    },
    {
      value: "returned_to_registry_by_coordinator",
      label: "File returned to Registry by Coordinator",
    },
  ];

  const investigationFileStatus = [
    {
      value: "sent_to_coordinator_by_investigation_officer",
      label: "File moved to Coordinator by Investigation Officer for review",
    },
    {
      value: "returned_to_registry_by_investigation_officer",
      label: "File returned to Registry by Investigation Officer",
    },
  ];

  // Datatable columns
  const registryColumns = [
    {
      name: "#",
      width: "3rem",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: <span className="font-weight-bold fs-13">File Reference No</span>,
      selector: (row) => row.file.reference_no,
      width: "15rem",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">File IR No</span>,
      selector: (row) => row.file.ir_no,
      width: "15rem",
      sortable: true,
    },
    {
      id: "dateSent",
      name: <span className="font-weight-bold fs-13">File Sent On</span>,
      sortable: true,
      cell: (row) => {
        if (row.file.date_sent) {
          return moment(row.file.date_sent).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">File Received On</span>,
      sortable: true,
      cell: (row) => {
        if (row.file.date_sent) {
          return moment(row.file.date_received).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: (
        <span className="font-weight-bold fs-13">File Last Updated On</span>
      ),
      sortable: true,
      cell: (row) => {
        if (row.date_time_updated) {
          return moment(row.date_time_updated).format("LLL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">File origin</span>,
      selector: (row) => row.file.origin.acronym,
      width: "5rem",
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">File Status</span>,
      selector: (row) => row.file_status,
      width: "15rem",
      sortable: false,
      cell: (row) => {
        switch (row.file_status) {
          case "received_in_registry_from_partner_institution":
            return (
              <span className="badge badge-soft-success">
                {" "}
                Received in Registry from Partner
              </span>
            );
          case "returned_to_registry_by_coordinator":
            return (
              <span className="badge badge-soft-success">
                Returned to Registry By Coordinator
              </span>
            );
          case "returned_to_registry_by_investigation_officer":
            return (
              <span className="badge badge-soft-success">
                Returned to Registry By Investigation Officer
              </span>
            );
          default:
            return <Label>None</Label>;
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: false,
      width: "20rem",
      cell: (row) => {
        return (
          <>
            <div className="d-flex flex-wrap gap-2">
              <Tooltip title="Update File">
                <button
                  className="btn btn-primary"
                  onClick={(e) =>
                    handleUpdateFile(
                      row.file.id,
                      row.file.reference_no,
                      row.file.date_sent,
                      row.file.date_received,
                      row.file.origin.id
                    )
                  }
                >
                  <i className="fas fa-edit" />
                </button>
              </Tooltip>

              <Tooltip title="Move File">
                <button
                  className="btn btn-success"
                  onClick={(e) =>
                    handleAssignFile(
                      e,
                      "registryModalBackdrop",
                      row.id,
                      row.file.id,
                      row.file.reference_no
                    )
                  }
                >
                  <i className="fas fa-arrows-alt-h" />
                </button>
              </Tooltip>
            </div>

            <Modal
              isOpen={registryModalBackdrop}
              toggle={() => {
                setRegistryModalBackdrop(!registryModalBackdrop);
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title">Assign File</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setRegistryModalBackdrop(false);
                  }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Form
                  className="form-group"
                  onSubmit={(e) => {
                    e.preventDefault();
                    AssignFileValidation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <label htmlFor="referenceNo" className="col-form-label">
                      File Reference Number:
                    </label>
                    <Input
                      disabled
                      name="referenceNo"
                      className="form-control"
                      placeholder="Reference No"
                      type="text"
                      onChange={fileMovementValidation.handleChange}
                      onBlur={fileMovementValidation.handleBlur}
                      value={referenceNo}
                      invalid={
                        fileMovementValidation.touched.referenceNo &&
                        fileMovementValidation.errors.referenceNo
                          ? true
                          : false
                      }
                    />

                    <Col xs={12} style={{ marginTop: "20px" }}>
                      <label
                        htmlFor="investigation-officers"
                        className="col-form-label"
                      >
                        File Status
                      </label>
                      <Select
                        name="file-status"
                        placeholder="Select File Status"
                        options={registryFileStatus}
                        onChange={handleFileStatusChange}
                      />
                    </Col>

                    {selectedFileStatus === "sent_to_coordinator_by_registry" ||
                    selectedFileStatus ===
                      "reopened_file_sent_to_coordinator_by_registry" ? (
                      <Col xs={12} style={{ marginTop: "20px" }}>
                        <label htmlFor="coordinator" className="col-form-label">
                          Coordinator
                        </label>
                        <Select
                          name="coordinator"
                          placeholder="Select Coordinator"
                          options={coordinators}
                          onChange={handleCoordinator}
                        />
                      </Col>
                    ) : (
                      ""
                    )}

                    {selectedFileStatus === "sent_to_partner_institution" ? (
                      <>
                        <Col xs={12} style={{ marginTop: "20px" }}>
                          <label
                            htmlFor="institutions"
                            className="col-form-label"
                          >
                            File Destination
                          </label>
                          <Select
                            name="institutions"
                            placeholder="Select Institution"
                            options={institutions.filter(
                              (institution) => !institution.isHost
                            )}
                            onChange={handleInstitution}
                          />
                        </Col>

                        {hasBranches ? (
                          <Col xs={12} style={{ marginTop: "20px" }}>
                            <label htmlFor="regions" className="col-form-label">
                              Regions
                            </label>
                            <Select
                              name="regions"
                              placeholder="Select Region"
                              options={regions}
                              onChange={handleRegion}
                            />
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col xs={12} style={{ marginTop: "20px" }}>
                          <label htmlFor="reasons" className="col-form-label">
                            Reason
                          </label>
                          <Select
                            name="reasons"
                            placeholder="Select Reason"
                            options={reasons.filter(
                              (item) => item.type === "registry"
                            )}
                            onChange={handleReasons}
                          />
                        </Col>

                        {chargedInCourt ? (
                          <>
                            <Col xs={12} style={{ marginTop: "20px" }}>
                              <label
                                htmlFor="offences"
                                className="col-form-label"
                              >
                                Offence
                              </label>
                              <Select
                                name="offences"
                                placeholder="Select Offence"
                                options={offences}
                                onChange={handleOffences}
                              />
                            </Col>
                          </>
                        ) : (
                          ""
                        )}

                        {chargedInCourt}
                        <Col xs={12} style={{ marginTop: "20px" }}>
                          <label htmlFor="offences" className="col-form-label">
                            Please enter more details
                          </label>
                          <TextArea onChange={handleMoreDetails} />
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setRegistryModalBackdrop(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn-primary"
                  onClick={() => handleUpdateFileTracker(registryModalBackdrop)}
                >
                  {savingStatus}
                </button>
              </div>
            </Modal>
          </>
        );
      },
    },
  ];

  const coordinatorColumns = [
    {
      name: "#",
      width: "3rem",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: <span className="font-weight-bold fs-13">File Reference No</span>,
      selector: (row) => row.file.reference_no,
      width: "15rem",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">File IR No</span>,
      selector: (row) => row.file.ir_no,
      width: "15rem",
      sortable: true,
    },
    {
      id: "dateSent",
      name: <span className="font-weight-bold fs-13">File Sent On</span>,
      sortable: true,
      cell: (row) => {
        if (row.file.date_sent) {
          return moment(row.file.date_sent).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      id: "dateReceived",
      name: <span className="font-weight-bold fs-13">File Received On</span>,
      sortable: true,
      cell: (row) => {
        if (row.file.date_received) {
          return moment(row.file.date_received).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      id: "bringUpDate",
      name: <span className="font-weight-bold fs-13">File Bring-up Date</span>,
      sortable: true,
      cell: (row) => {
        if (row.file.bring_up_date) {
          return moment(row.file.bring_up_date).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: (
        <span className="font-weight-bold fs-13">File Last Updated On</span>
      ),
      sortable: true,
      cell: (row) => {
        if (row.date_time_updated) {
          return moment(row.date_time_updated).format("LLL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">File Origin</span>,
      selector: (row) => row.file.origin.acronym,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">File Status</span>,
      selector: (row) => row.file_status,
      width: "15rem",
      sortable: false,
      cell: (row) => {
        switch (row.file_status) {
          case "sent_to_coordinator_by_registry":
            return (
              <span className="badge badge-soft-info">
                Sent to Coordinator By Registry
              </span>
            );
          case "reopened_file_sent_to_coordinator_by_registry":
            return (
              <span className="badge badge-soft-danger">
                Re-opened File Sent to Coordinator By Registry
              </span>
            );
          case "sent_to_coordinator_by_investigation_officer":
            return (
              <span className="badge badge-soft-primary">
                Sent to Coordinator By Investigation Officer
              </span>
            );
          default:
            return <Label>None</Label>;
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: false,
      width: "15rem",
      cell: (row) => {
        return (
          <>
            <div className="d-flex flex-wrap gap-2">
              <Tooltip title="Process File">
                <button
                  className="btn btn-primary"
                  onClick={(e) =>
                    handleAssignFile(
                      e,
                      "coordinatorModalBackdrop",
                      row.id,
                      row.file.id,
                      row.file.reference_no
                    )
                  }
                >
                  <i className="fas fa-cogs" />
                </button>
              </Tooltip>
            </div>

            <Modal
              isOpen={coordinatorModalBackdrop}
              toggle={() => {
                setCoordinatorModalBackdrop(!coordinatorModalBackdrop);
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title">Process File</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setCoordinatorModalBackdrop(false);
                  }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Form
                  className="form-group"
                  onSubmit={(e) => {
                    e.preventDefault();
                    AssignFileValidation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <label htmlFor="referenceNo" className="col-form-label">
                      File Reference Number:
                    </label>
                    <Input
                      disabled
                      name="referenceNo"
                      className="form-control"
                      placeholder="Reference No"
                      type="text"
                      onChange={fileMovementValidation.handleChange}
                      onBlur={fileMovementValidation.handleBlur}
                      value={referenceNo}
                      invalid={
                        fileMovementValidation.touched.referenceNo &&
                        fileMovementValidation.errors.referenceNo
                          ? true
                          : false
                      }
                    />

                    <Col xs={12} style={{ marginTop: "20px" }}>
                      <label
                        htmlFor="investigation-officers"
                        className="col-form-label"
                      >
                        File Status
                      </label>
                      <Select
                        name="file-status"
                        placeholder="File Status"
                        options={coordinatorFileStatus}
                        onChange={handleFileStatusChange}
                      />
                    </Col>
                    {selectedFileStatus !==
                    "returned_to_registry_by_coordinator" ? (
                      <Col xs={12} style={{ marginTop: "20px" }}>
                        <label
                          htmlFor="investigation-officers"
                          className="col-form-label"
                        >
                          Investigation Officer
                        </label>
                        <Select
                          name="investigation-officers"
                          placeholder="Investigation Officers"
                          options={investigationOfficers}
                          onChange={handleInvestigationOfficerChange}
                        />
                      </Col>
                    ) : (
                      ""
                    )}

                    {selectedFileStatus !==
                    "returned_to_registry_by_coordinator" ? (
                      <>
                        <Col xs={12} style={{ marginTop: "20px" }}>
                          <Label className="form-label">Bring-up Date</Label>
                          <Input
                            name="bringUpDate"
                            className="form-control"
                            placeholder="Bring-up Date."
                            type="date"
                            onChange={handleBringUpDate}
                          />
                        </Col>

                        <Col xs={12} style={{ marginTop: "20px" }}>
                          <label
                            htmlFor="investigation-officers"
                            className="col-form-label"
                          >
                            Reason
                          </label>
                          <Select
                            name="reasons"
                            placeholder="Select Reason"
                            options={reasons.filter(
                              (item) => item.type === "coordinator"
                            )}
                            onChange={handleReasons}
                          />
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setCoordinatorModalBackdrop(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn-success"
                  onClick={() =>
                    handleUpdateFileTracker(coordinatorModalBackdrop)
                  }
                >
                  {savingStatus}
                </button>
              </div>
            </Modal>
          </>
        );
      },
    },
  ];

  const investigationsColumns = [
    {
      name: "#",
      width: "3rem",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: <span className="font-weight-bold fs-13">File Reference No</span>,
      selector: (row) => row.file.reference_no,
      width: "15rem",
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">File IR No</span>,
      selector: (row) => row.file.ir_no,
      width: "15rem",
      sortable: true,
    },
    {
      id: "dateSent",
      name: <span className="font-weight-bold fs-13">File Sent On</span>,
      sortable: true,
      cell: (row) => {
        if (row.file.date_sent) {
          return moment(row.file.date_sent).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">File Received On</span>,
      sortable: true,
      cell: (row) => {
        if (row.file.date_received) {
          return moment(row.file.date_received).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Bring-up Date</span>,
      sortable: true,
      cell: (row) => {
        if (row.file.date_received) {
          return moment(row.bring_up_date).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: (
        <span className="font-weight-bold fs-13">File Last Updated On</span>
      ),

      sortable: true,
      cell: (row) => {
        if (row.date_time_updated) {
          return moment(row.date_time_updated).format("LLL");
        } else {
          return "N/A";
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Assigned Officer</span>,
      selector: (row) =>
        `${row.assigned_officer.first_name} ${row.assigned_officer.last_name}`,
      sortable: false,
      width: "15rem",
    },
    {
      name: <span className="font-weight-bold fs-13">Reason</span>,
      sortable: false,
      width: "15rem",
      cell: (row) => {
        switch (row.reason) {
          case "preliminary_investigation":
            return (
              <span className="badge badge-soft-secondary">
                {" "}
                Preliminary Investigation
              </span>
            );
          case "further_investigation":
            return (
              <span className="badge badge-soft-danger">
                Further Investigation
              </span>
            );
          case "legal_opinion":
            return <span className="badge badge-soft-info">Legal Opinion</span>;
          default:
            return <Label></Label>;
        }
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: false,
      width: "15rem",
      cell: (row) => {
        return (
          <>
            <div className="d-flex flex-wrap gap-2">
              <Tooltip title="Coordinator Review">
                <button
                  className="btn-primary"
                  onClick={(e) =>
                    handleAssignFile(
                      e,
                      "investigationModalBackdrop",
                      row.id,
                      row.file.id,
                      row.file.reference_no
                    )
                  }
                >
                  <i className="fas fa-eye" />
                </button>
              </Tooltip>
            </div>

            <Modal
              isOpen={investigationModalBackdrop}
              toggle={() => {
                setInvestigationModalBackdrop(!investigationModalBackdrop);
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title">Coordinator Review</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setInvestigationModalBackdrop(false);
                  }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <Form
                  className="form-group"
                  onSubmit={(e) => {
                    e.preventDefault();
                    AssignFileValidation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <label htmlFor="referenceNo" className="col-form-label">
                      File Reference Number:
                    </label>
                    <Input
                      disabled
                      name="referenceNo"
                      className="form-control"
                      placeholder="Reference No"
                      type="text"
                      onChange={fileMovementValidation.handleChange}
                      onBlur={fileMovementValidation.handleBlur}
                      value={referenceNo}
                      invalid={
                        fileMovementValidation.touched.referenceNo &&
                        fileMovementValidation.errors.referenceNo
                          ? true
                          : false
                      }
                    />

                    <Col xs={12} style={{ marginTop: "20px" }}>
                      <label
                        htmlFor="investigation-officers"
                        className="col-form-label"
                      >
                        File Status
                      </label>
                      <Select
                        name="file-status"
                        placeholder="File Status"
                        options={investigationFileStatus}
                        onChange={handleFileStatusChange}
                      />
                    </Col>

                    {selectedFileStatus ==
                    "sent_to_coordinator_by_investigation_officer" ? (
                      <Col xs={12} style={{ marginTop: "20px" }}>
                        <label htmlFor="coordinator" className="col-form-label">
                          Coordinator
                        </label>
                        <Select
                          name="coordinator"
                          placeholder="Select Coordinator"
                          options={coordinators}
                          onChange={handleCoordinator}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setInvestigationModalBackdrop(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    handleUpdateFileTracker(investigationModalBackdrop);
                  }}
                >
                  {savingStatus}
                </button>
              </div>
            </Modal>
          </>
        );
      },
    },
  ];

  // Select data on change events in react select
  const handleInvestigationOfficerChange = (event) => {
    setSelectedAssignedOfficer(event.value);
  };

  const handleFileStatusChange = (event) => {
    setSelectedFileStatus(event.value);
  };

  const handleInstitution = (event) => {
    setSelectedInstitution(event.value);

    if (event.hasBranches) {
      setHasBranches(true);
    } else {
      setHasBranches(false);
    }
  };

  const handleRegion = (event) => {
    setSelectedRegion(event.value);
  };

  const handleCoordinator = (event) => {
    setSelectedCoordinator(event.value);
  };

  const handleReasons = (event) => {
    setSelectedReason(event.value);

    if (event.value === "charged_in_court") {
      setChargedInCourt(true);
    } else {
      setChargedInCourt(false);
    }
  };

  const handleOffences = (event) => {
    setSelectedOffence(event.value);
  };

  const handleMoreDetails = (event) => {
    setMoreOffenceDetails(event.target.value);
  };

  //API Calls
  const fetchFileTracker = () => {
    setFileTracker([]);
    try {
      getFileTracker(null).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setFileTracker(res);
          setFileTrackerLoaded(true);
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  //Effects
  useEffect(() => {
    if (fileTrackerLoaded) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [fileTracker]);

  useEffect(() => {
    fetchFileTracker();
  }, [loadedFileTracker]);

  
  return (
    <Card>
      <CardBody>
        <CardTitle className="h4">File Movement Tracker</CardTitle>
        {role !== "investigation_officer" ? (
          <div className="p-2 d-flex flex-wrap gap-2">
            <button className="btn btn-primary" onClick={handleShowFileTracker}>
              <i className="fas fa-reply me-1 align-middle"></i> Go Back
            </button>
          </div>
        ) : (
          ""
        )}

        <div className="p-3">
          <Row>
            {/* <Nav tabs className="nav-pills nav-justified"> */}
            <Nav tabs className="nav-tabs-custom nav-justified">
              {role === "registry_staff" || role === "administrator" ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <i className="far fa-folder-open align-middle"> </i>{" "}
                    Registry{" "}
                    {fileTracker.filter(
                      (data) =>
                        data.file_status ===
                          "received_in_registry_from_partner_institution" ||
                        data.file_status ===
                          "returned_to_registry_by_coordinator" ||
                        data.file_status ===
                          "returned_to_registry_by_investigation_officer"
                    ).length > 0 ? (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                        {" "}
                        {
                          fileTracker.filter(
                            (data) =>
                              data.file_status ===
                                "received_in_registry_from_partner_institution" ||
                              data.file_status ===
                                "returned_to_registry_by_coordinator" ||
                              data.file_status ===
                                "returned_to_registry_by_investigation_officer"
                          ).length
                        }
                      </span>
                    ) : (
                      ""
                    )}
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}

              {role === "coordinator" || role === "administrator" ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <i className="fas fa-eye me-1 align-middle"> </i>{" "}
                    Coordinator{" "}
                    {fileTracker.filter(
                      (data) =>
                        data.file_status ===
                          "sent_to_coordinator_by_registry" ||
                        data.file_status ===
                          "sent_to_coordinator_by_investigation_officer" ||
                        data.file_status ===
                          "reopened_file_sent_to_coordinator_by_registry"
                    ).length > 0 ? (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                        {" "}
                        {
                          fileTracker.filter(
                            (data) =>
                              data.file_status ===
                                "sent_to_coordinator_by_registry" ||
                              data.file_status ===
                                "sent_to_coordinator_by_investigation_officer" ||
                              data.file_status ===
                                "reopened_file_sent_to_coordinator_by_registry"
                          ).length
                        }
                      </span>
                    ) : (
                      ""
                    )}
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}

              {role === "registry_staff" ||
              role === "administrator" ||
              role === "investigation_officer" ? (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    <i className="fas fa-people-arrows me-1 align-middle"> </i>{" "}
                    Investigations{" "}
                    {role === "investigation_officer" ? (
                      fileTracker.filter(
                        (data) =>
                          (data.file_status ===
                            "assigned_to_investigation_officer" ||
                            data.file_status ===
                              "returned_to_investigation_officer") &&
                          data.assigned_officer !== null &&
                          data.assigned_officer.id === parseInt(userId)
                      ).length > 0 ? (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                          {" "}
                          {
                            fileTracker.filter(
                              (data) =>
                                (data.file_status ===
                                  "assigned_to_investigation_officer" ||
                                  data.file_status ===
                                    "returned_to_investigation_officer") &&
                                data.assigned_officer !== null &&
                                data.assigned_officer.id === parseInt(userId)
                            ).length
                          }
                        </span>
                      ) : (
                        ""
                      )
                    ) : fileTracker.filter(
                        (data) =>
                          data.file_status ===
                            "assigned_to_investigation_officer" ||
                          data.file_status ===
                            "returned_to_investigation_officer"
                      ).length > 0 ? (
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                        {" "}
                        {
                          fileTracker.filter(
                            (data) =>
                              data.file_status ===
                                "assigned_to_investigation_officer" ||
                              data.file_status ===
                                "returned_to_investigation_officer"
                          ).length
                        }
                      </span>
                    ) : (
                      ""
                    )}
                  </NavLink>
                </NavItem>
              ) : (
                ""
              )}
            </Nav>

            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              {role === "registry_staff" || role === "administrator" ? (
                <TabPane tabId="1">
                  <Row>
                    <CardBody>
                      <div>
                        <Link
                          className="btn btn-success"
                          onClick={handleNewFile}
                        >
                          <i className="mdi mdi-file"></i> Create new file
                        </Link>
                      </div>
                    </CardBody>

                    {fileTrackerLoaded && isLoaded ? (
                      <Col sm="12">
                        <DataTable
                          columns={registryColumns}
                          data={fileTracker
                            .filter(
                              (data) =>
                                data.file_status ===
                                  "received_in_registry_from_partner_institution" ||
                                data.file_status ===
                                  "returned_to_registry_by_coordinator" ||
                                data.file_status ===
                                  "returned_to_registry_by_investigation_officer"
                            )
                            .sort((a, b) =>
                              a.file.reference_no < b.file.reference_no ? -1 : 1
                            )}
                          defaultSortFieldId="dateSent"
                          noDataComponent="No available data"
                          pagination
                        />
                      </Col>
                    ) : (
                      <Spinner />
                    )}
                  </Row>
                </TabPane>
              ) : (
                ""
              )}

              {role === "coordinator" || role === "administrator" ? (
                <TabPane tabId="2">
                  <Row>
                    {fileTrackerLoaded && isLoaded ? (
                      <Col sm="12">
                        <DataTable
                          columns={coordinatorColumns}
                          data={fileTracker
                            .filter(
                              (data) =>
                                data.file_status ===
                                  "sent_to_coordinator_by_registry" ||
                                data.file_status ===
                                  "sent_to_coordinator_by_investigation_officer" ||
                                data.file_status ===
                                  "reopened_file_sent_to_coordinator_by_registry"
                            )
                            .sort((a, b) =>
                              a.file.reference_no < b.file.reference_no ? -1 : 1
                            )}
                          defaultSortFieldId="dateSent"
                          noDataComponent="No available data"
                          pagination
                        />
                      </Col>
                    ) : (
                      <Spinner />
                    )}
                  </Row>
                </TabPane>
              ) : (
                ""
              )}

              {role === "registry_staff" ||
              role === "administrator" ||
              role === "investigation_officer" ? (
                <TabPane tabId="3">
                  <Row>
                    {fileTrackerLoaded && isLoaded ? (
                      <Col sm="12">
                        {role === "investigation_officer" ? (
                          <DataTable
                            columns={investigationsColumns}
                            data={fileTracker
                              .filter(
                                (data) =>
                                  (data.file_status ===
                                    "assigned_to_investigation_officer" ||
                                    data.file_status ===
                                      "returned_to_investigation_officer") &&
                                  data.assigned_officer !== null &&
                                  data.assigned_officer.id === parseInt(userId)
                              )
                              .sort((a, b) =>
                                a.file.reference_no < b.file.reference_no
                                  ? -1
                                  : 1
                              )}
                            defaultSortFieldId="dateSent"
                            noDataComponent="No available data"
                            pagination
                          />
                        ) : (
                          <DataTable
                            columns={investigationsColumns}
                            data={fileTracker
                              .filter(
                                (data) =>
                                  data.file_status ===
                                    "assigned_to_investigation_officer" ||
                                  data.file_status ===
                                    "returned_to_investigation_officer"
                              )
                              .sort((a, b) =>
                                a.file.reference_no < b.file.reference_no
                                  ? -1
                                  : 1
                              )}
                            defaultSortFieldId="dateSent"
                            noDataComponent="No available data"
                            pagination
                          />
                        )}
                      </Col>
                    ) : (
                      <Spinner />
                    )}
                  </Row>
                </TabPane>
              ) : (
                ""
              )}
            </TabContent>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default Main;
