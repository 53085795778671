import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  CardTitle,
} from "reactstrap";
import RadialChart1 from "../ChartTypes/RadialChart";
import {
  getFileSummary,
  getFileStatusSummary,
} from "../../../helpers/backend_helper";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import Spinner from "../../../components/Spinner/index";

const Main = ({
  filterType,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  reason,
}) => {
  let navigate = useNavigate();

  const [fileSummary, setFileSummary] = useState([]);

  const [totalFilesReceived, setTotalFilesReceived] = useState(0);
  const [totalFileStillInvestigated, setTotalFileStillInvestigated] =
    useState(0);
  const [totalFilesCompleteInvestigation, setTotalFilesCompleteInvestigation] =
    useState(0);

  const [fileSummaryLoaded, setFileSummaryLoaded] = useState(false);

  const fetchFileSummary = () => {
    setFileSummary([]);

    let selectedFilters = {
      filterType: filterType,
      startDate: startDate,
      endDate: endDate,
      reason: reason,
    };

    try {
      getFileSummary(selectedFilters).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setFileSummary(res);
          setFileSummaryLoaded(true);
          handleStartDateChange(res.resolved_filters.start_date);
          handleEndDateChange(res.resolved_filters.end_date);
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  const fileColumns = [
    {
      name: "#",
      width: "3rem",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: <span className="font-weight-bold fs-13">File Reference No</span>,
      selector: (row) => row.file__reference_no,
      width: "15rem",
      sortable: true,
    },
    {
      id: "dateUpdated",
      name: <span className="font-weight-bold fs-13">File Dispatched On</span>,
      sortable: true,
      cell: (row) => {
        if (row.date_time_updated) {
          return moment(row).format("LLL");
        } else {
          return "N/A";
        }
      },
    },
    {
      id: "dateSent",
      name: <span className="font-weight-bold fs-13">File Sent On</span>,
      sortable: true,
      cell: (row) => {
        if (row.file__date_sent) {
          return moment(row).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      id: "dateReceived",
      name: <span className="font-weight-bold fs-13">File Received On</span>,
      sortable: true,
      cell: (row) => {
        if (row.file__date_received) {
          return moment(row.file__date_received).format("LL");
        } else {
          return "N/A";
        }
      },
    },
    {
      id: "institution",
      name: <span className="font-weight-bold fs-13">File Destination</span>,
      sortable: true,
      cell: (row) => row.destination__acronym,
    },
    {
      id: "region",
      name: <span className="font-weight-bold fs-13">Region</span>,
      sortable: true,
      cell: (row) => row.region__description,
    },
    {
      id: "reason",
      name: <span className="font-weight-bold fs-13">Reason</span>,
      sortable: true,
      cell: (row) => {
        switch (row.reason) {
          case "preliminary_investigation":
            return <Label>Preliminary Investigation</Label>;
          case "legal_opinion":
            return <Label> Legal Opinion</Label>;
          case "disciplinary_action":
            return <Label>Disciplinary Action</Label>;
          case "charged_in_court":
            return <Label>Charged in Court</Label>;
          case "closed_no_further_action":
            return <Label>Closed - No Further Action</Label>;
          case "closed_no_offence_detected":
            return <Label>Closed - No Offence Detected</Label>;
          case "closed_civil":
            return <Label>Closed - Civil</Label>;
          default:
            return <Label>None</Label>;
        }
      },
    },
  ];

  useEffect(() => {
    fetchFileSummary();
  }, [filterType, reason, startDate, endDate]);

  console.log("loaded data", fileSummary);

  return (
    <React.Fragment>
      <Card>
        <CardTitle>Files Summary</CardTitle>
        <CardBody>
          <Row>
            <Col xl={4} sm={6}>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div id="radialchart-1" className="apex-charts" dir="ltr">
                        <RadialChart1
                          id="radialchart-1"
                          className="apex-charts"
                          dir="ltr"
                          value={
                            Object.keys(fileSummary).length > 0
                              ? parseInt(
                                  (fileSummary.files_summary[0]
                                    .total_files_with_disciplinary_action /
                                    fileSummary.files_summary[0]
                                      .total_files_received) *
                                    100
                                )
                              : 0
                          }
                        />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="mb-1">Files with Disciplinary Action</p>
                      <h5 className="mb-3">
                        {Object.keys(fileSummary).length > 0 ? (
                          fileSummary.files_summary[0]
                            .total_files_with_disciplinary_action
                        ) : (
                          <Spinner />
                        )}
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} sm={6}>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div id="radialchart-2" className="apex-charts" dir="ltr">
                        <RadialChart1
                          id="radialchart-2"
                          className="apex-charts"
                          dir="ltr"
                          value={
                            Object.keys(fileSummary).length > 0
                              ? parseInt(
                                  (fileSummary.files_summary[0]
                                    .total_files_charged_in_court /
                                    fileSummary.files_summary[0]
                                      .total_files_received) *
                                    100
                                )
                              : 0
                          }
                        />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="mb-1">Files Charged in Court</p>
                      <h5 className="mb-3">
                        {Object.keys(fileSummary).length > 0 ? (
                          fileSummary.files_summary[0]
                            .total_files_charged_in_court
                        ) : (
                          <Spinner />
                        )}
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} sm={6}>
              <Card>
                <CardBody>
                  <div className="d-flex text-muted">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div id="radialchart-3" className="apex-charts" dir="ltr">
                        <RadialChart1
                          id="radialchart-3"
                          className="apex-charts"
                          dir="ltr"
                          value={
                            Object.keys(fileSummary).length > 0
                              ? parseInt(
                                  (fileSummary.files_summary[0]
                                    .total_files_closed_no_further_action /
                                    fileSummary.files_summary[0]
                                      .total_files_received) *
                                    100
                                )
                              : 0
                          }
                        />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="mb-1">Files Closed - No Further Action</p>
                      <h5 className="mb-3">
                        {Object.keys(fileSummary).length > 0 ? (
                          fileSummary.files_summary[0]
                            .total_files_closed_no_further_action
                        ) : (
                          <Spinner />
                        )}
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} sm={6}>
              <Card>
                <CardBody>
                  <div className="d-flex text-muted">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div id="radialchart-4" className="apex-charts" dir="ltr">
                        <RadialChart1
                          id="radialchart-4"
                          className="apex-charts"
                          dir="ltr"
                          value={
                            Object.keys(fileSummary).length > 0
                              ? parseInt(
                                  (fileSummary.files_summary[0]
                                    .total_files_closed_no_offence_detected /
                                    fileSummary.files_summary[0]
                                      .total_files_received) *
                                    100
                                )
                              : 0
                          }
                        />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="mb-1">
                        Files Closed - No Offences Detected
                      </p>
                      <h5 className="mb-3">
                        {Object.keys(fileSummary).length > 0 ? (
                          fileSummary.files_summary[0]
                            .total_files_closed_no_offence_detected
                        ) : (
                          <Spinner />
                        )}
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} sm={6}>
              <Card>
                <CardBody>
                  <div className="d-flex text-muted">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div id="radialchart-4" className="apex-charts" dir="ltr">
                        <RadialChart1
                          id="radialchart-4"
                          className="apex-charts"
                          dir="ltr"
                          value={
                            Object.keys(fileSummary).length > 0
                              ? parseInt(
                                  (fileSummary.files_summary[0]
                                    .total_files_closed_civil /
                                    fileSummary.files_summary[0]
                                      .total_files_received) *
                                    100
                                )
                              : 0
                          }
                        />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="mb-1">Files Closed - Civil</p>
                      <h5 className="mb-3">
                        {Object.keys(fileSummary).length > 0 ? (
                          fileSummary.files_summary[0].total_files_closed_civil
                        ) : (
                          <Spinner />
                        )}
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} sm={6}>
              <Card>
                <CardBody>
                  <div className="d-flex text-muted">
                    <div className="flex-shrink-0 me-3 align-self-center">
                      <div id="radialchart-4" className="apex-charts" dir="ltr">
                        <RadialChart1
                          id="radialchart-4"
                          className="apex-charts"
                          dir="ltr"
                          value={
                            Object.keys(fileSummary).length > 0
                              ? parseInt(
                                  (fileSummary.files_summary[0]
                                    .total_files_for_further_clarification /
                                    fileSummary.files_summary[0]
                                      .total_files_received) *
                                    100
                                )
                              : 0
                          }
                        />
                      </div>
                    </div>

                    <div className="flex-grow-1 overflow-hidden">
                      <p className="mb-1">
                        Files Returned - Further Clarification{" "}
                      </p>
                      <h5 className="mb-3">
                        {Object.keys(fileSummary).length > 0 ? (
                          fileSummary.files_summary[0]
                            .total_files_for_further_clarification
                        ) : (
                          <Spinner />
                        )}
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Row>
        <Col xl={12}>
          <Card>
            <CardTitle>File Movement Disaggregated by Status</CardTitle>
            <h6 style={{ marginTop: "10px", marginBottom: "10px" }}>
              FC - Files Carried Forward, RF - Files Received, PF - Files
              Pending at Registry, WC - Files With coordinator, SI - Files under
              investigation, CI - Completed Investigation
            </h6>
            <div className="table-responsive">
              <table
                className="table table-hover mb-0"
                style={{ border: "1px light gray" }}
              >
                <thead>
                  <tr style={{ border: "solid 1px black" }}>
                    <th>#</th>
                    <th style={{ border: "solid 1px black" }}>
                      <h6>LEA</h6>
                    </th>

                    {fileSummary.file_status_summary
                      ? fileSummary.file_status_summary.overall_results_list[0].data.map(
                          (item, index) => (
                            <>
                              <th style={{ border: "solid 1px black" }}>
                                <h6>
                                  {item.month}/{item.year}
                                </h6>
                                <th style={{ width: "3rem" }}>FC</th>
                                <th style={{ width: "3rem" }}>RF</th>
                                <th style={{ width: "3rem" }}>PF</th>
                                <th style={{ width: "3rem" }}>WC</th>
                                <th style={{ width: "3rem" }}>SI</th>
                                <th style={{ width: "3rem" }}>CI</th>
                              </th>
                            </>
                          )
                        )
                      : ""}
                  </tr>
                </thead>

                <tbody>
                  {fileSummary.file_status_summary ? (
                    fileSummary.file_status_summary.overall_results_list.map(
                      (item, index) => (
                        <tr>
                          <td style={{ border: "solid 1px black" }}>
                            {index + 1}
                          </td>
                          <td style={{ border: "solid 1px black" }}>
                            {item.institution_acronym}
                          </td>
                          {item.data.map((dataItem, dataIndex) => (
                            <td style={{ border: "solid 1px black" }}>
                              <td
                                style={{
                                  width: "2rem",
                                  fontWeight: "bolder",
                                  color: "red",
                                }}
                              >
                                {dataItem.files_carried_forward}
                              </td>
                              <td
                                style={{
                                  width: "2rem",
                                  fontWeight: "bolder",
                                  color: "green",
                                }}
                              >
                                {dataItem.files_received}
                              </td>
                              <td style={{ width: "2rem" }}>
                                {dataItem.files_pending_at_registry}
                              </td>
                              <td style={{ width: "2rem" }}>
                                {dataItem.with_coordinator}
                              </td>
                              <td style={{ width: "2rem" }}>
                                {dataItem.still_investigated}
                              </td>
                              <td style={{ width: "2rem" }}>
                                {dataItem.completed_investigation}
                              </td>
                            </td>
                          ))}
                        </tr>
                      )
                    )
                  ) : (
                    <Spinner />
                  )}
                </tbody>

                <tfoot>
                  <tr>
                    <td colspan={2} style={{ border: "solid 1px black" }}>
                      <strong>TOTALS</strong>
                    </td>

                    {fileSummary.file_status_summary ? (
                      fileSummary.file_status_summary.totals.map(
                        (item, index) => (
                          <>
                            <td style={{ border: "solid 1px black" }}>
                              <strong>
                                <td style={{ width: "3rem", color: "red" }}>
                                  {item.total_files_carried_forward}
                                </td>
                                <td style={{ width: "3rem", color: "green" }}>
                                  {item.total_files_received}
                                </td>
                                <td style={{ width: "3rem" }}>
                                  {item.total_files_pending_at_registry}
                                </td>
                                <td style={{ width: "3rem" }}>
                                  {item.total_files_with_coordinator}
                                </td>
                                <td style={{ width: "3rem" }}>
                                  {item.total_still_investigated}
                                </td>
                                <td style={{ width: "3rem" }}>
                                  {item.total_completed_investigation}
                                </td>
                              </strong>
                            </td>
                          </>
                        )
                      )
                    ) : (
                      <Spinner />
                    )}
                  </tr>
                </tfoot>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col xl={12}>
          <Card>
            <CardTitle>Files Dispatched Disaggregated by Status</CardTitle>
            <CardBody>
              <Col sm="12">
                {fileSummaryLoaded === true ? (
                  <DataTable
                    columns={fileColumns}
                    data={fileSummary.file_data}
                    defaultSortFieldId="dateSent"
                    noDataComponent="No available data"
                    pagination
                  />
                ) : (
                  <Spinner />
                )}
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </React.Fragment>
  );
};

export default Main;
