import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Alert,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner/index";
import { getTrendsData } from "../../../helpers/backend_helper";
import LineChart from "../ChartTypes/AntDCharts/LineChart";
import AreaChart from "../ChartTypes/AntDCharts/AreaChart";
import MultiLineChart from "../ChartTypes/AntDCharts/MultiLineChart";
import MultiAreaChart from "../ChartTypes/AntDCharts/MultiAreaChart";
import StackedBarChart from "../ChartTypes/AntDCharts/StackedBarChart";
import BarChart from "../ChartTypes/AntDCharts/BarChart";
import VerticalGroupedBarChart from "../ChartTypes/AntDCharts/VerticalGroupedBarChart";

const Trends = () => {
  let navigate = useNavigate();

  const [referralsSent, setReferralsSent] = useState([]);
  const [casesFiled, setCasesFiled] = useState([]);
  const [casesValueOpened, setCasesValueOpened] = useState([]);
  const [casesStatus, setCasesStatus] = useState([]);
  const [casesEnded, setCasesEnded] = useState([]);
  const [assetStatus, setAssetStatus] = useState([]);

  const fetchTrendsData = () => {
    try {
      getTrendsData().then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setReferralsSent(res.referrals_sent);
          setCasesFiled(res.cases_filed);
          setCasesValueOpened(res.cases_value_opened_for_investigation);
          setCasesStatus(res.cases_status);
          setCasesEnded(res.cases_ended);
          setAssetStatus(res.asset_status);
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  useEffect(() => {
    fetchTrendsData();
  }, []);

  return (
    <>
      <div>
        <Row>
          <Col xl={12} sm={12}>
            <Card>
              <CardBody>
                <div className="apex-charts" dir="ltr">
                  <h2>Referrals and PETs</h2>
                  <div className="mt-4">
                    {referralsSent ? (
                      <VerticalGroupedBarChart loadedData={referralsSent} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl={12} sm={12}>
            <Card>
              <CardBody>
                <div className="apex-charts" dir="ltr">
                  <h2>Cases Filed in Court</h2>
                  <div className="mt-4">
                    {casesFiled ? (
                      <LineChart loadedData={casesFiled} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div className="apex-charts" dir="ltr">
                  <h2>Aggregate Value of GC Files opened for investigation</h2>
                  <div className="mt-4">
                    {casesValueOpened ? (
                      <LineChart loadedData={casesValueOpened} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl={12} sm={12}>
            <Card>
              <CardBody>
                <div className="apex-charts" dir="ltr">
                  <h2>Cases Prosecution and Conviction</h2>
                  <div className="mt-4">
                    {casesStatus ? (
                      <MultiLineChart loadedData={casesStatus} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div className="apex-charts" dir="ltr">
                  <h2>Cases ended by status</h2>
                  <div className="mt-4">
                    {casesEnded ? (
                      <MultiLineChart loadedData={casesEnded} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl={12} sm={12}>
            <Card>
              <CardBody>
                <div className="apex-charts" dir="ltr">
                  <h2>Asset Recovery</h2>
                  <div className="mt-4">
                    {assetStatus ? (
                      <MultiAreaChart loadedData={assetStatus} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Trends;
