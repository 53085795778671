import React, { useEffect, useState } from "react";
import {
  getDataPoints,
  getPeriodTypes,
  getPeriods,
  getRegions,
  postInstitutionalData,
  getSpecificRegionData,
  getPreviousPeriod,
  getDefaultPeriodType,
  exposeInstitutionalData,
} from "../../helpers/backend_helper";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Divider } from "antd";
import Spinner from "../../components/Spinner/index";
import moment from "moment";
import {
  Form,
  Button,
  InputGroupText,
  InputGroup,
  Input,
  Col,
  Row,
  CardBody,
  Alert,
  Label,
  Modal,
  FormGroup,
} from "reactstrap";
import { Stack, Grid } from "@mui/material";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";

const Main = ({ stepNo, onDataSubmit }) => {
  let navigate = useNavigate();
  let requiresDataExposure = localStorage.getItem("requiresDataExposure");

  const randomSubmissionNo = () => {
    const number = Math.floor(Math.random() * 10) + 1;
    return number;
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [dataPoints, setDataPoints] = useState([]);
  const [postedData, setPostedData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [defaultPeriod, setDefaultPeriod] = useState({});
  const [defaultPeriodType, setDefaultPeriodType] = useState({});
  const [periodTypes, setPeriodTypes] = useState([]);
  const [regions, setRegions] = useState([]);

  const [selectedPeriod, setSelectedPeriod] = useState();
  const [selectedPeriodType, setSelectedPeriodType] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedYear, setSelectedYear] = useState(new Date());
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [visibleRedundancyAlert, setVisibleRedundancyAlert] = useState(false);
  const [submissionNo, setSubmissionNo] = useState(randomSubmissionNo());
  const [sessionExpired, setSessionExpired] = useState(false);
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loadedRegion, setLoadedRegion] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const [openDetails, setOpenDetails] = useState(false);
  // const [details, setDetails] = useState("");
  const [selectedLabelIndex, setSelectedLabelIndex] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [enteredDetails, setEnteredDetails] = useState("");

  // Handle events
  const handleConfirm = () => {
    if (!showConfirmMsg) {
      setShowConfirmMsg(true);
    }
  };

  const handleCancelConfirm = () => {
    setShowConfirmMsg(false);
  };

  //Handle change of input box
  const handleInputChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    const newState = [...dataPoints];
    // const index = dataPoints.findIndex((x) => {x.data_point.data_point_id === parseInt(id)});

    let parentDataIndex = -1;
    let childDataIndex = -1;

    if (value >= 0) {
      dataPoints.map((data, dataIndex) => {
        let dataPoints = data.data_point;

        dataPoints.findIndex((x) => {
          if (x.data_point_id === parseInt(id)) {
            parentDataIndex = dataIndex;
            childDataIndex = x.data_point_id;
            x.value = value;
          }
        });
      });

      if (parentDataIndex > -1) {
        let dataItem = dataPoints[parentDataIndex];

        newState[dataItem] = {
          ...newState[dataItem],
        };

        setDataPoints(newState);
      }

      setPostedData((postedData) => ({
        ...postedData,
        [id]: { value: parseInt(value), details: "" },
      }));
    }
  };

  //Handle adding more details to values
  const handleMoreDetails = (event) => {
    const details = event.target.value;
    const value = selectedItem.value;

    let parentDataIndex = -1;
    let childDataIndex = -1;

    let id = selectedItem.data_point_id;
    const newState = [...dataPoints];

    dataPoints.map((data, dataIndex) => {
      let dataPoints = data.data_point;

      dataPoints.findIndex((x) => {
        if (x.data_point_id === parseInt(id)) {
          parentDataIndex = dataIndex;
          childDataIndex = x.data_point_id;
          x.value = value;
          x.details = details;
        }
      });
    });

    if (parentDataIndex > -1) {
      let dataItem = dataPoints[parentDataIndex];

      newState[dataItem] = {
        ...newState[dataItem],
      };

      setDataPoints(newState);
    }

    setEnteredDetails(details);

    setPostedData((postedData) => ({
      ...postedData,
      [id]: { value: parseInt(value), details: details },
    }));
  };

  const handleViewMore = (index, item) => {
    if (item.value !== "") {
      setOpenDetails(!openDetails);
    }

    setSelectedLabelIndex(index);
    setSelectedValue(item.value);
    setSelectedItem(item);

    setEnteredDetails(item.details);
  };

  const handleIsError = () => {
    setIsError(true);
    setTimeout(() => {
      setIsError(false);
    }, 2000);
  };

  // Submit data
  const handleSubmit = (e) => {
    setIsSubmitting(true);
    if (selectedPeriod && selectedPeriodType && selectedRegion) {
      //post data here
      let submittedData = {
        filters: {
          selectedPeriod: selectedPeriod,
          selectedPeriodType: selectedPeriodType,
          selectedRegion: selectedRegion,
          selectedYear: selectedYear.getFullYear(),
        },
        postedData: postedData,
      };

      postInstitutionalData(submittedData).then((res) => {
        if (res.status === 200) {
          setPostedData([]);
          fetchDataPoints();
          setSubmissionNo(submissionNo + 1);
          onDataSubmit(submissionNo + 1);
        } else if (res.status === 406) {
          setVisibleRedundancyAlert(true);
          setTimeout(() => {
            setVisibleRedundancyAlert(false);
          }, 2000);
        }
      });
    } else {
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
      }, 2000);
    }

    setIsSubmitting(false);
  };

  const handleDataExposing = (e) => {
    //post data here

    try {
      let submittedData = {
        filters: {
          selectedCategoryId: stepNo,
        },
      };

      exposeInstitutionalData(submittedData).then((res) => {
        if (res.status === 200) {
          setPostedData([]);
          fetchDataPoints();
          onDataSubmit(submissionNo + 1);
        }
      });
    } catch (error) {
      setVisibleAlert(true);
      setTimeout(() => {
        setVisibleAlert(false);
      }, 2000);
    }

    setShowConfirmMsg(false);
  };

  const handleYearChange = (event) => {
    if (new Date().getFullYear() !== new Date(event).getFullYear()) {
      handleIsError();
    } else {
      console.log(new Date(event))
      setSelectedYear(new Date(event));
    }
  };

  const handleRegionChange = async (event) => {
    setSelectedRegion(event.value);
    setLoadedRegion(event.label);
    setShowLoader(true);

    let regionId = event.value;

    if (stepNo && regionId && selectedPeriod) {
      let objectData = {
        categoryId: stepNo,
        regionId: regionId,
        periodId: selectedPeriod,
        year: selectedYear.getFullYear(),
      };

      try {
        await getSpecificRegionData(objectData).then((res) => {
          setRegionData(res);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    setShowLoader(false);
  };

  // Fetch Data from APIs
  const fetchDataPoints = async () => {
    setDataPoints([]);

    try {
      await getDataPoints(null).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setDataPoints(res);
          setIsLoaded(true);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchPeriods = async () => {
    setPeriods([]);

    try {
      await getPeriods(null).then((res) => {
        res.map((data) => {
          const value = data.id;
          const label = data.description;

          let dataObject = { value: value, label: label };

          setPeriods((current) => [...current, dataObject]);
        });
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchPeriodTypes = async () => {
    setPeriodTypes([]);

    try {
      await getPeriodTypes(null).then((res) => {
        res.map((data) => {
          const value = data.id;
          const label = data.description;

          let dataObject = { value: value, label: label };

          setPeriodTypes((current) => [...current, dataObject]);
        });
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchRegions = async () => {
    setRegions([]);

    try {
      await getRegions(null).then((res) => {
        res.map((data) => {
          const value = data.id;
          const label = data.description;

          let dataObject = { value: value, label: label };

          setRegions((current) => [...current, dataObject]);
        });
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchPreviousPeriod = async () => {
    try {
      await getPreviousPeriod(null).then((res) => {
        setDefaultPeriod(res);
        setSelectedPeriod(res.value);
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  const fetchDefaultPeriodType = async () => {
    try {
      await getDefaultPeriodType(null).then((res) => {
        setDefaultPeriodType(res);
        setSelectedPeriodType(res.value);
      });
    } catch (error) {
      setSessionExpired(true);
      console.error("Error fetching data:", error);
    }
  };

  // Call React Hooks
  useEffect(() => {
    fetchPreviousPeriod();
  }, []);

  useEffect(() => {
    fetchDefaultPeriodType();
  }, []);

  useEffect(() => {
    fetchPeriods();
  }, []);

  useEffect(() => {
    fetchPeriodTypes();
  }, []);

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    fetchDataPoints();
  }, []);

  useEffect(() => {
    if (dataPoints) {
      const newState = [...dataPoints];

      let value = 0;

      dataPoints.map((data) => {
        value = 0;
        data.data_point.map((dataItem) => {
          if (dataItem.data_point__category__tab_no === stepNo) {
            if (regionData && regionData.length > 0) {
              let found = regionData.find(
                (item) => item.data_point.id === dataItem.data_point_id
              );

              if (found) {
                dataItem.value = found.value;
                dataItem.details = found.more_details;
                dataItem.period_type = found.period_type.description;

                newState[dataItem] = {
                  ...newState[dataItem],
                };

                setPostedData((postedData) => ({
                  ...postedData,
                  [dataItem.data_point_id]: {
                    value: found.value,
                    details: found.more_details,
                  },
                }));
              } else {
                dataItem.value = 0;
                newState[dataItem] = {
                  ...newState[dataItem],
                };

                setPostedData((postedData) => ({
                  ...postedData,
                  [dataItem.data_point_id]: { value: 0, details: "" },
                }));
              }
            } else {
              dataPoints.map((data) => {
                data.data_point.map((dataItem) => {
                  if (dataItem.data_point__category__tab_no === stepNo) {
                    dataItem.value = 0;
                    dataItem.period_type = "";

                    newState[dataItem] = {
                      ...newState[dataItem],
                    };

                    setPostedData((postedData) => ({
                      ...postedData,
                      [dataItem.data_point_id]: { value: 0, details: "" },
                    }));
                  }
                });
              });
            }

            setDataPoints(newState);
          }
        });
      });
    }
  }, [regionData]);

  useEffect(() => {
    if (sessionExpired) {
      navigate("/logout");
    }
  }, [sessionExpired]);

  return (
    <>
      <Modal
        size="lg"
        isOpen={openDetails}
        toggle={() => {
          setOpenDetails(!openDetails);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title">More Details on selected Data point</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <h2 className="font-bold">
            DATAPOINT => {selectedItem.data_point__label}
          </h2>
          <h2 className="font-bold">VALUE => {selectedValue}</h2>

          <div style={{ paddingTop: "2%" }}>
            {selectedItem.state === "editable" ? (
              <FormGroup>
                <Label>
                  (Please enter any further details about the value of data
                  point)
                </Label>
                <Input
                  type="textarea"
                  onChange={handleMoreDetails}
                  value={enteredDetails}
                />
              </FormGroup>
            ) : (
              <FormGroup>
                <Label>(Further details for the above value)</Label>
                <h3>{enteredDetails}</h3>
              </FormGroup>
            )}
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn-danger"
            data-bs-dismiss="modal"
            onClick={() => setOpenDetails(false)}
          >
            Close
          </button>
        </div>
      </Modal>

      <Form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
          return false;
        }}
      >
        <input name="csrfToken" type="hidden" />
        <CardBody>
          <h2>Period Details</h2>
          <Row>
            <Col xs={12} md={3} lg={3} style={{ marginTop: "20px" }}>
              <Select
                placeholder="Select Period Type..."
                options={periodTypes}
                value={
                  selectedPeriodType > 0 && periodTypes.length > 0
                    ? periodTypes.filter(
                        (periodType) => periodType.value === selectedPeriodType
                      )
                    : defaultPeriodType
                }
                onChange={(event) => setSelectedPeriodType(event.value)}
              />
            </Col>

            <Col xs={12} md={3} lg={3} style={{ marginTop: "20px" }}>
              <Select
                placeholder="Select Period..."
                options={periods}
                value={
                  selectedPeriod > 0 && periods.length > 0
                    ? periods.filter(
                        (period) => period.value === selectedPeriod
                      )
                    : defaultPeriod
                }
                onChange={(event) => {
                  setSelectedPeriod(event.value);
                }}
              />
            </Col>

            <Col xs={12} md={3} lg={3} style={{ marginTop: "20px" }}>
              <Select
                placeholder="Select Region..."
                options={regions}
                onChange={handleRegionChange}
              />
            </Col>

            <Col xs={12} md={4} lg={3} style={{ marginTop: "20px" }}>
              <Datetime
                value={selectedYear}
                dateFormat="YYYY"
                timeFormat={false}
                onChange={handleYearChange}
                closeOnSelect
              />

              {isError ? (
                <Alert color="danger">
                  Action not allowed. Please contact administrator.
                </Alert>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          <h2 className="mb-3 bold underline">
            ***All greyed-out input boxes contain data from other institutions
            and will be available once shared.***
          </h2>

          {showLoader ? (
            <Spinner />
          ) : (
            <div>
              {loadedRegion !== "" ? (
                <h2 style={{ marginTop: "20px", color: "green" }}>
                  SELECTED REGION: {loadedRegion}
                </h2>
              ) : (
                ""
              )}
              {dataPoints.map((data, index) => (
                <Grid item xs={8} md={12} key={index}>
                  {stepNo === data.tab_no && data.data_point.length > 0 ? (
                    <div className="mt-3">
                      {" "}
                      <Divider plain>
                        <h2
                          className="badge badge-soft-success"
                          style={{
                            fontSize: "13px",
                            wordWrap: "normal",
                            fontWeight: "bold",
                          }}
                        >
                          {data.section.toUpperCase()}
                        </h2>
                      </Divider>
                    </div>
                  ) : (
                    ""
                  )}

                  {data.data_point.length > 0
                    ? data.data_point.map((item, itemIndex) => (
                        <div key={itemIndex}>
                          {stepNo === item.data_point__category__tab_no ? (
                            <Row className="mb-3">
                              <Label
                                htmlFor={item.data_point__code}
                                className="col-md-12 col-form-label"
                              >
                                {itemIndex + 1}) {item.data_point__label}
                              </Label>

                              {/* </label> */}
                              <div className="col-md-2">
                                <InputGroup>
                                  <InputGroupText>
                                    {item.state === "editable" ? (
                                      <EditOutlined
                                        onClick={() =>
                                          handleViewMore(itemIndex + 1, item)
                                        }
                                      />
                                    ) : (
                                      <EyeOutlined
                                        onClick={() =>
                                          handleViewMore(itemIndex + 1, item)
                                        }
                                      />
                                    )}
                                  </InputGroupText>
                                  <Input
                                    key={index}
                                    className="form-control form-control-color w-25"
                                    id={item.data_point_id}
                                    name={item.data_point__code}
                                    type="number"
                                    value={item.value}
                                    onChange={handleInputChange}
                                    disabled={
                                      item.state === "editable" ? false : true
                                    }
                                  />
                                </InputGroup>
                              </div>

                              <div>
                                {item.period_type ? (
                                  <h6 style={{ color: "#0BB197" }}>
                                    Period Type: {item.period_type}
                                  </h6>
                                ) : (
                                  <h6 style={{ color: "#0BB197" }}>
                                    No data available
                                  </h6>
                                )}
                              </div>
                            </Row>
                          ) : null}
                        </div>
                      ))
                    : ""}
                </Grid>
              ))}
            </div>
          )}

          {isLoaded ? (
            <Stack spacing={2} direction="row" alignItems="center">
              <Button color="primary" type="submit">
                {isSubmitting ? "Please wait ..." : "Save Data"}
              </Button>

              {requiresDataExposure ? (
                <Button
                  color="success"
                  className="d-grid"
                  onClick={handleConfirm}
                >
                  Expose Data to Other BIs
                </Button>
              ) : (
                ""
              )}

              {showConfirmMsg && (
                <div>
                  <br /> Are you sure want to Expose this data to Other BIs?{" "}
                  <Button color="success" onClick={handleDataExposing}>
                    Yes
                  </Button>
                  <Button
                    onClick={handleCancelConfirm}
                    className="btn btn-danger"
                    style={{ marginLeft: 3 }}
                  >
                    No
                  </Button>
                </div>
              )}
            </Stack>
          ) : (
            <Spinner />
          )}
        </CardBody>

        {visibleAlert ? (
          <Alert color="danger">
            Please make sure all required fields are filled in.
          </Alert>
        ) : (
          ""
        )}

        {visibleRedundancyAlert ? (
          <Alert color="danger">
            Posted data has already been submitted for Visualization.
          </Alert>
        ) : (
          ""
        )}
      </Form>
    </>
  );
};

export default Main;
