import React, { Suspense, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Progress,
  NavLink,
  NavItem,
} from "reactstrap";

import classnames from "classnames";
import { getDataPointCategories } from "../../helpers/backend_helper";
import DataEntryForm from "./form-fields";
import DraftTable from "./draft-table";
import { useNavigate, Link } from "react-router-dom";
import Spinner from "../../components/Spinner/index"


const Main = () => {
  let navigate = useNavigate();

  const [activeTabwiz, setoggleTabwiz] = useState(0);
  const [passedStepswiz, setpassedStepswiz] = useState([0]);
  const [tabMultiplier, setTabMultiplier] = useState(0);
  const [submissionNo, setSubmissionNo] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const [steps, setSteps] = useState([]);

  function toggleTabwiz(tab) {
    if (activeTabwiz !== tab) {
      var modifiedSteps = [...passedStepswiz, tab];
      if (tab >= 1 && tab <= steps.length) {
        setoggleTabwiz(tab);
        setpassedStepswiz(modifiedSteps);
        setTabMultiplier(100 / steps.length);
      }
    }
  }

  const updateCategoryData = (props) => {
    setSubmissionNo(props);
  };

  const fetchData = () => {
    try {
      getDataPointCategories(null).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setSteps(res);
          setIsLoaded(true);
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <Row>
            {isLoaded ? (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        {steps.map((data, index) => (
                          <NavItem
                            key={index}
                            className={classnames({
                              active: activeTabwiz === index + 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTabwiz === index + 1,
                              })}
                              onClick={() => {
                                // This line ensures the count on the tab items starts from one, despite the actual tab no configured in the backend
                                toggleTabwiz(index + 1);

                                // This line ensures the actual tab no of the selected category is passed to the draft table for filtering.
                                setSelectedCategoryId(
                                  data.data_point_category.tab_no
                                );
                              }}
                            >
                              <span className="step-number">{index + 1}</span>
                              <span
                                className="step-title"
                                style={{ paddingLeft: "10px" }}
                              >
                                {data.data_point_category.description}
                              </span>
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>

                      {tabMultiplier === 0 ? (
                        <CardBody>
                          <div className="mt-4">
                            <p>
                              Please select a Report category above to submit
                              your report for the previous quarter.
                            </p>
                          </div>
                        </CardBody>
                      ) : (
                        ""
                      )}

                      {tabMultiplier > 0 ? (
                        <>
                          <CardBody>
                            <div id="bar" className="mt-4">
                              <div className="mb-4">
                                <Progress
                                  value={tabMultiplier * activeTabwiz}
                                  color="success"
                                  animated
                                ></Progress>
                              </div>
                            </div>
                          </CardBody>

                          <TabContent
                            activeTab={activeTabwiz}
                            className="twitter-bs-wizard-tab-content"
                          >
                            <Suspense
                              fallback={<div>Loading Form details ...</div>}
                            >
                              {steps.length > 0
                                ? steps.map((item, index) => (
                                    <TabPane tabId={index + 1} key={index}>
                                      <DataEntryForm
                                        // stepNo={activeTabwiz}
                                        stepNo={item.data_point_category.tab_no}
                                        onDataSubmit={updateCategoryData}
                                      />
                                    </TabPane>
                                  ))
                                : ""}
                            </Suspense>

                            <Row>
                              <Col lg={12}>
                                <Card>
                                  <CardBody>
                                    <h4 className="card-title">Data Summary</h4>
                                    <div className="table-responsive">
                                      <DraftTable
                                        categoryId={selectedCategoryId}
                                        submissionNo={submissionNo}
                                      />
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabContent>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <Spinner />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Main;
