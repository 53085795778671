import axios from "axios";
import config from "../config";
import { isExpired } from "react-jwt";
import * as url from "./url_helper";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";

// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = () => {
  const token = localStorage.getItem("accessToken");

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  get = async (url, params) => {
    //Check token and refresh as required
    const token = await checkValidityAndRefreshTokenOnExpiry();

    if (token) {
      return await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        params: { data: params },
      });
    }
    return false;
  };

  get_public = async (url, params) => {
    //Check token and refresh as required
    const response = await axios.get(url);

    return response;
  };

  /**
   * post given data to url
   */
  create_public = async (url, data) => {
    //Check token and refresh as required
    const response = axios.post(url, data);

    return response;
  };

  create = async (url, data) => {
    //Check token and refresh as required
    const token = await checkValidityAndRefreshTokenOnExpiry();

    if (token) {
      return await axios.post(url, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
    return false;
  };

  /**
   * Updates data
   */
  update = async (url, data) => {
    const token = await checkValidityAndRefreshTokenOnExpiry();

    if (token) {
      return await axios.put(url, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
    return false;
  };

  /**
   * Delete
   */
  delete = async (url, data) => {
    const token = await checkValidityAndRefreshTokenOnExpiry();

    if (token) {
      return axios.delete(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
    return false;
  };
}

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const checkValidityAndRefreshTokenOnExpiry = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  const isMyTokenExpired = isExpired(accessToken);

  if (isMyTokenExpired) {
    //access token expired
    try {
      let newAccessToken = null;
      const response = await axios.post(url.RENEW_JWT_TOKEN, {
        refresh: refreshToken,
      });

      newAccessToken = response["access"];

      localStorage.setItem("accessToken", response["access"]);
      localStorage.setItem("refreshToken", response["refresh"]);

      if (newAccessToken) {
        return newAccessToken;
      } else {
        return null;
      }
    } catch (error) {
      console.log("Error occured.", error);
      return null;
    }
  } else {
    return localStorage.getItem("accessToken");
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
