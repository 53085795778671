import React from "react";
import { Container } from "reactstrap";
import { Card, CardContent } from "@mui/material";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Stepper from "./stepper";

const Main = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={localStorage.getItem("project")} breadcrumbItem="Data Entry" />
        </Container>
        <Card>
          <CardContent>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title">Grand Corruption Data</h5>
              </div>
            </div>

            <Stepper />

          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Main;
