import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/plots';

const StackedBarChart = ({loadedData}) => {
    
const data = loadedData;

  const config = {
    data: data.reverse(),
    isStack: true,
    xField: 'value',
    yField: 'institution',
    seriesField: 'type',
    color: [" #0047AB", "#006D5B", "#800020", "#4B0082", "#D69408", "#708090"],
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        }, 
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
  };
  return <Bar {...config} />;
};

export default StackedBarChart;
