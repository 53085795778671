import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getSubmittedDataAllInstitutions,
  getSubmittedData,
} from "../../../helpers/backend_helper";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Collapse, Divider } from "antd";
import Spinner from "../../../components/Spinner/index";

const Main = () => {
  let navigate = useNavigate();
  let role = localStorage.getItem("role");

  let nf = new Intl.NumberFormat();

  const [submissionData, setSubmissionData] = useState([]);
  const [submissionDataAllInstitutions, setSubmissionDataAllInstitutions] =
    useState([]);
  const [isRendering, setIsRendering] = useState(true);

  const handleRendering = () => {
    setTimeout(() => {
      setIsRendering(false);
    }, 2000);
  };

  const fetchSubmissionData = async (props) => {
    try {
      getSubmittedData(props).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setSubmissionData(res);

          handleRendering();
        }
      });
    } catch (error) {
      console.error("Error loading submission data", error);
    }
  };

  const fetchSubmissionDataAllInstitutions = async (props) => {
    try {
      getSubmittedDataAllInstitutions(props).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setSubmissionDataAllInstitutions(res);

          handleRendering();
        }
      });
    } catch (error) {
      console.error("Error loading submission data", error);
    }
  };

  useEffect(() => {
    fetchSubmissionData();
  }, []);

  useEffect(() => {
    fetchSubmissionDataAllInstitutions();
  }, []);

  console.log(submissionData)

  return (
    <div className="page-content">
      <Container fluid={true}>
        <>
          <Breadcrumbs
            title={localStorage.getItem("project")}
            breadcrumbItem="Periodic Submissions"
          />

          {isRendering ? <Spinner /> : ""}

          {role !== "supervisor" && submissionData !== undefined
            ? submissionData.map((item, index) => (
                <Collapse
                  onChange={handleRendering}
                  key={index}
                  accordion
                  items={[
                    {
                      key: "1",
                      label: item.year_label,
                      children: item.period_data.map((periodItem, index) => (
                        <Collapse
                          key={index}
                          items={[
                            {
                              key: "1",
                              label: (
                                <span
                                  className="badge badge-soft-success"
                                  style={{
                                    fontSize: "13px",
                                    wordWrap: "normal",
                                  }}
                                >
                                  {periodItem.period}
                                </span>
                              ),
                              children: periodItem.category_data.map(
                                (categoryItem, index) => (
                                  <Collapse
                                    key={index}
                                    items={[
                                      {
                                        key: "1",
                                        label: (
                                          <span
                                            className="badge badge-soft-info"
                                            style={{
                                              fontSize: "13px",
                                              wordWrap: "normal",
                                            }}
                                          >
                                            {categoryItem.category}
                                          </span>
                                        ),
                                        children: (
                                          <>
                                            <Row className="mb-3">
                                              <div className="table-responsive">
                                                <table className="table table-hover mb-0">
                                                  <thead>
                                                    <tr>
                                                      <th>#</th>
                                                      <th>Region</th>
                                                      {categoryItem
                                                        .regional_data[0] ? (
                                                        <>
                                                          {Object.keys(
                                                            categoryItem
                                                              .regional_data[0]
                                                              .data
                                                          ).map(
                                                            (key, index) => (
                                                              <th key={index}>
                                                                {key}
                                                              </th>
                                                            )
                                                          )}
                                                        </>
                                                      ) : null}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {categoryItem.regional_data.map(
                                                      (regionalItem, index) => (
                                                        <tr key={index}>
                                                          <td
                                                            style={{
                                                              width: "2rem",
                                                              alignItems:
                                                                "left",
                                                            }}
                                                          >
                                                            {index + 1}
                                                          </td>

                                                          <td
                                                            style={{
                                                              width: "5rem",
                                                            }}
                                                          >
                                                            <h6>
                                                              {
                                                                regionalItem.region
                                                              }
                                                            </h6>
                                                          </td>

                                                          {Object.keys(
                                                            regionalItem.data
                                                          ).map((key) => {
                                                            return (
                                                              <td
                                                                style={{
                                                                  width: "5rem",
                                                                }}
                                                                key={key}
                                                              >
                                                                <h6>
                                                                  {nf.format(
                                                                    regionalItem
                                                                      .data[key]
                                                                  )}
                                                                </h6>
                                                              </td>
                                                            );
                                                          })}
                                                        </tr>
                                                      )
                                                    )}

                                                  {categoryItem.regional_totals}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </Row>
                                          </>
                                        ),
                                      },
                                    ]}
                                  />
                                )
                              ),
                            },
                          ]}
                        />
                      )),
                    },
                  ]}
                />
              ))
            : role === "supervisor" &&
              submissionDataAllInstitutions !== undefined
            ? submissionDataAllInstitutions.map((item, index) => (
                <Collapse
                  onChange={handleRendering}
                  key={index}
                  accordion
                  items={[
                    {
                      key: "1",
                      label: item.institution,
                      children: item.year_data.map((institutionItem, index) => (
                        <Collapse
                          key={index}
                          accordion
                          items={[
                            {
                              key: "1",
                              label: institutionItem.year_label,
                              children: institutionItem.period_data.map(
                                (periodItem, index) => (
                                  <Collapse
                                    key={index}
                                    items={[
                                      {
                                        key: "1",
                                        label: (
                                          <span
                                            className="badge badge-soft-success"
                                            style={{
                                              fontSize: "13px",
                                              wordWrap: "normal",
                                            }}
                                          >
                                            {periodItem.period}
                                          </span>
                                        ),
                                        children: periodItem.category_data.map(
                                          (categoryItem, index) => (
                                            <Collapse
                                              key={index}
                                              items={[
                                                {
                                                  key: "1",
                                                  label: (
                                                    <span
                                                      className="badge badge-soft-info"
                                                      style={{
                                                        fontSize: "13px",
                                                        wordWrap: "normal",
                                                      }}
                                                    >
                                                      {categoryItem.category}
                                                    </span>
                                                  ),
                                                  children: (
                                                    <>
                                                      <Row className="mb-3">
                                                        <div className="table-responsive">
                                                          <table className="table table-hover mb-0">
                                                            <thead>
                                                              <tr>
                                                                <th>#</th>
                                                                <th>Region</th>
                                                                {categoryItem
                                                                  .regional_data[0] ? (
                                                                  <>
                                                                    {Object.keys(
                                                                      categoryItem
                                                                        .regional_data[0]
                                                                        .data
                                                                    ).map(
                                                                      (
                                                                        key,
                                                                        index
                                                                      ) => (
                                                                        <th
                                                                          key={
                                                                            index
                                                                          }
                                                                        >
                                                                          {key}
                                                                        </th>
                                                                      )
                                                                    )}
                                                                  </>
                                                                ) : null}
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {categoryItem.regional_data.map(
                                                                (
                                                                  regionalItem,
                                                                  index
                                                                ) => (
                                                                  <tr
                                                                    key={index}
                                                                  >
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "2rem",
                                                                        alignItems:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      {index +
                                                                        1}
                                                                    </td>

                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "5rem",
                                                                      }}
                                                                    >
                                                                      <h6>
                                                                        {
                                                                          regionalItem.region
                                                                        }
                                                                      </h6>
                                                                    </td>

                                                                    {Object.keys(
                                                                      regionalItem.data
                                                                    ).map(
                                                                      (key) => {
                                                                        return (
                                                                          <td
                                                                            style={{
                                                                              width:
                                                                                "5rem",
                                                                            }}
                                                                            key={
                                                                              key
                                                                            }
                                                                          >
                                                                            <h6>
                                                                              {
                                                                                nf.format(
                                                                                regionalItem
                                                                                  .data[
                                                                                  key
                                                                                ])
                                                                              }
                                                                            </h6>
                                                                          </td>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </tr>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </Row>
                                                    </>
                                                  ),
                                                },
                                              ]}
                                            />
                                          )
                                        ),
                                      },
                                    ]}
                                  />
                                )
                              ),
                            },
                          ]}
                        />
                      )),
                    },
                  ]}
                />
              ))
            : ""}
        </>
      </Container>
    </div>
  );
};

export default Main;
