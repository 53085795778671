import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Form,
} from "reactstrap";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

//API
import { updatePassword } from "../../helpers/backend_helper";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  document.title = `Change Password | ${localStorage.getItem("project")}`;
  let navigate = useNavigate();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const submitPasswordChanges = () => {
    if (password === confirmPassword) {
      let submittedData = {
        password: password,
      };

      updatePassword(submittedData).then((res) => {
        console.log("status", res.status);
        if (res.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);

            localStorage.removeItem("authUser");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("trackFiles");

            navigate("/logout");
          }, 2000);
        } else if (res.status === 406) {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 2000);
        }
      });
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title={localStorage.getItem("project")}
            breadcrumbItem="Change Password"
          />

          <Row>
            <Col lg={6}>
              {error ? (
                <Alert color="danger">
                  <div>
                    <h2>Passwords do not match, please try again.</h2>
                  </div>
                </Alert>
              ) : null}
              {success ? (
                <Alert color="success" style={{ marginTop: "13px" }}>
                  Password Change successful
                </Alert>
              ) : null}
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Form
                className="form-group"
                onSubmit={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                <div className="form-group">
                  <Row className="mb-3">
                    <Col md={6} lg={6}>
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        type="password"
                        onChange={handlePassword}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={6} lg={6}>
                      <Label className="form-label">Confirm Password</Label>
                      <Input
                        name="confirm-password"
                        className="form-control"
                        placeholder="Confirm Password"
                        type="password"
                        onChange={handleConfirmPassword}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="text-center mt-4">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={submitPasswordChanges}
                  >
                    Update password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
