import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ButtonGroup,
  Button,
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getQuestionnaireResults } from "../../helpers/backend_helper";
import { useNavigate } from "react-router-dom";
import { Statistic } from "antd";
import CountUp from "react-countup";
import Donut from "../Dashboard/ChartTypes/AntDCharts/Donut";
import PieChart from "../Dashboard/ChartTypes/AntDCharts/PieChart";
import GroupedBarChart from "../Dashboard/ChartTypes/AntDCharts/GroupedBarChart";
import Spinner from "../../components/Spinner/index";
import DataTable from "react-data-table-component";

const Main = () => {
  let navigate = useNavigate();

  const [filterType, setFilterType] = useState("");
  const [experiences, setExperiences] = useState([]);
  const [roles, setRoles] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [insufficientResources, setInsufficientResources] = useState([]);
  const [caseComplexity, setCaseComplexity] = useState([]);
  const [recommendationsHandlingCases, setRecommendationsHandlingCases] =
    useState([]);
  const [recommendationsReducingDelays, setRecommendationsReducingDelays] =
    useState([]);
  const [
    recommendationsEnhanceEfficiency,
    setRecommendationsEnhanceEfficiency,
  ] = useState([]);

  const [operationalChallenges, setOperationalChallenges] = useState([]);
  const [
    witnessCooperationAndProsecution,
    setWitnessCooperationAndProsecution,
  ] = useState([]);

  const [politicalAndPublicPressure, setPoliticalAndPublicPressure] = useState(
    []
  );
  const [totals, setTotals] = useState({});

  const [
    reasonsForFewConvictionsOverAcquittals,
    setReasonsForFewConvictionsOverAcquittals,
  ] = useState([]);
  const [reasonsImpactOfDelaysInGCCases, setReasonsImpactOfDelaysInGCCases] =
    useState([]);
  const [delayFactorsForGCInAjudication, setDelayFactorsForGCInAjudication] =
    useState([]);
  const [
    delayFactorsForProsecutionInGCCases,
    setDelayFactorsForProsecutionInGCCases,
  ] = useState([]);

  const columns = [
    {
      name: "#",
      width: "3rem",
      cell: (row, index) => index + 1, //RDT provides index by default
    },
    {
      name: <span className="font-weight-bold fs-13">Response</span>,
      selector: (row) => row.value.toLowerCase(),
      sortable: true,
    },
  ];

  const formatter = (value) => <CountUp end={value} separator="," />;
  const fetchQuestionnaireResults = () => {
    let selectedFilters = {
      filterType: filterType,
    };

    try {
      getQuestionnaireResults(selectedFilters).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setExperiences(res.experiences);
          setRoles(res.roles);
          setInstitutions(res.institutions);
          setTotals(res.totals);
          setInsufficientResources(res.insufficient_resources);

          setRecommendationsHandlingCases(
            res.short_answer_summary.recommendations_for_handling_gc_cases
          );
          setRecommendationsReducingDelays(
            res.short_answer_summary.recommendations_for_reducing_delays
          );
          setRecommendationsEnhanceEfficiency(
            res.short_answer_summary.recommendations_to_enhance_efficiency
          );

          setCaseComplexity(res.case_complexity);
          setOperationalChallenges(res.operational_challenges);
          setWitnessCooperationAndProsecution(
            res.witness_cooperation_and_prosecution
          );
          setPoliticalAndPublicPressure(res.political_and_public_pressure);

          setReasonsForFewConvictionsOverAcquittals(
            res.reasons_for_few_convictions_over_acquittals
          );
          setReasonsImpactOfDelaysInGCCases(res.impact_of_delays_in_gc_cases);
          setDelayFactorsForGCInAjudication(
            res.delay_factors_for_gc_in_adjudication
          );
          setDelayFactorsForProsecutionInGCCases(
            res.delay_factors_for_prosecution_in_gc_cases
          );
        }
      });
    } catch (error) {
      console.error("Error occured in ", error);
    }
  };

  useEffect(() => {
    fetchQuestionnaireResults();
  }, [filterType]);

  return (
    <div className="page-content">
      <Container fluid={true}>
        <Breadcrumbs
          title={localStorage.getItem("project")}
          breadcrumbItem="Questionnaire Results"
        />
      </Container>

          <Row>
            <Col xl={6} md={12} sm={12}>
              <Card>
                <CardBody>
                  <ButtonGroup>
                    <Button
                      color="primary"
                      outline
                      onClick={() => setFilterType("general_questionnaire")}
                      active={filterType === "general_questionnaire"}
                    >
                      General Questionnaire
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={() => setFilterType("specific_questionnaire")}
                      active={filterType === "specific_questionnaire"}
                    >
                      Specific Questionnaire
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={() => setFilterType("")}
                      active={filterType === ""}
                    >
                      None
                    </Button>
                  </ButtonGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6} md={6} sm={6}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <Statistic
                      title="General Questionnaires"
                      value={totals.general_questionnaire}
                      formatter={formatter}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6} md={6} sm={6}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <Statistic
                      title="Specific Questionnaires"
                      value={totals.specific_questionnaire}
                      precision={2}
                      formatter={formatter}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={4} md={12} sm={12}>
              <Card>
                <CardBody>
                  {/* <div className="apex-charts" dir="ltr"> */}
                  <h2>Work Experience</h2>
                  {experiences !== "" ? (
                    <Donut loadedData={experiences} />
                  ) : (
                    <Spinner />
                  )}
                  {/* </div> */}
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} md={12} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>Roles</h2>
                    {roles !== "" ? <Donut loadedData={roles} /> : <Spinner />}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4} md={12} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>institutions</h2>
                    {institutions !== "" ? (
                      <Donut loadedData={institutions} />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Single Select */}
          <Row>
            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>
                      Responses for Insufficient Resources playing a role in
                      delays of investigations of GC Cases
                    </h2>
                    {experiences !== "" ? (
                      <div style={{ paddingTop: "5%" }}>
                        <PieChart loadedData={insufficientResources} />
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  <div>
                    <h2>
                      Responses for Complexity of Cases playing a role in delays
                      of investigations of GC Cases
                    </h2>
                    {experiences !== "" ? (
                      <div style={{ paddingTop: "5%" }}>
                        <PieChart loadedData={caseComplexity} />
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>
                      Responses for Operational Challenges playing a role in
                      delays of investigations of GC Cases
                    </h2>
                    {experiences !== "" ? (
                      <div style={{ paddingTop: "5%" }}>
                        <PieChart loadedData={operationalChallenges} />
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>
                      Responses for Witness Cooperation And Prosecution playing
                      a role in delays of investigations of GC Cases
                    </h2>
                    {experiences !== "" ? (
                      <div style={{ paddingTop: "5%" }}>
                        <PieChart
                          loadedData={witnessCooperationAndProsecution}
                        />
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>
                      Responses for Political and Public pressure playing a role
                      in delays of investigations of GC Cases
                    </h2>
                    {experiences !== "" ? (
                      <div style={{ paddingTop: "5%" }}>
                        <PieChart loadedData={politicalAndPublicPressure} />
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Multiple responses */}
          <Row>
            <Col xl={12} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>Reasons for few convictions over acquittals</h2>
                    {experiences !== "" ? (
                      <div style={{ paddingTop: "5%" }}>
                        <GroupedBarChart
                          loadedData={reasonsForFewConvictionsOverAcquittals}
                        />
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={12} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>Impact of Delays to GC Cases</h2>
                    {experiences !== "" ? (
                      <div style={{ paddingTop: "5%" }}>
                        <GroupedBarChart
                          loadedData={reasonsImpactOfDelaysInGCCases}
                        />
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={12} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>
                      Factors causing delays in GC Cases from an Adjudication
                      perspective
                    </h2>
                    {experiences !== "" ? (
                      <div style={{ paddingTop: "5%" }}>
                        <GroupedBarChart
                          loadedData={delayFactorsForGCInAjudication}
                        />
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={12} sm={12}>
              <Card>
                <CardBody>
                  <div className="apex-charts">
                    <h2>Factors causing delays in Prosecution of GC Cases</h2>
                    {experiences !== "" ? (
                      <div style={{ paddingTop: "5%" }}>
                        <GroupedBarChart
                          loadedData={delayFactorsForProsecutionInGCCases}
                        />
                      </div>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Free text */}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="m-4">
                    <h2>Recommendations for Handling Grand Corruption Cases</h2>

                    {recommendationsHandlingCases.length > 0 ? (
                      <DataTable
                        columns={columns}
                        data={recommendationsHandlingCases}
                        noDataComponent="No available data"
                        pagination
                      />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody>
                  <div className="m-4">
                    <h2>Recommendations for Reducing Delays</h2>

                    {recommendationsReducingDelays.length > 0 ? (
                      <DataTable
                        columns={columns}
                        data={recommendationsReducingDelays}
                        noDataComponent="No available data"
                        pagination
                      />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody>
                  <div className="m-4">
                    <h2>Recommendations to enhance efficiency</h2>

                    {recommendationsEnhanceEfficiency.length > 0 ? (
                      <DataTable
                        columns={columns}
                        data={recommendationsEnhanceEfficiency}
                        noDataComponent="No available data"
                        pagination
                      />
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>

  );
};

export default Main;
